import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: 'rgb(204,160,44)',
      light: 'rgba(255,216,47,0.52)',
      main: 'rgb(240, 190, 50)',
      contrastText: '#000',
    },
    secondary: {
      light: '#33eb91',
      main: '#00e676',
      dark: '#00a152',
      contrastText: '#000',
    },
  },
  typography: {
    h4: {
      padding: 10,
    },
    // h5: {
    //   fontStyle: 'italic',
    //   margin: 10,
    //   color: 'rgb(172, 170, 170)',
    // },
    // h6: {
    //   fontStyle: 'italic',
    //   color: 'rgb(172, 170, 170)',
    //   textAlign: 'justify',
    // },
  },
});

export default theme;

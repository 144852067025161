import React from 'react';
import { func, instanceOf, string } from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import withStyles from '@material-ui/styles/withStyles';

import styles from './styles';
import auth from 'lib/auth';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * Компонент навигационного меню.
 *
 * @param classes - объект с именами классов, скомпилированных withStyles;
 * @param title - Заголовок для отображения в навигационной панеле;
 * @param onOpenMenu - Callback нажатия на кнопку открывающую меню;
 *
 * @return {*}
 * @constructor
 */
const NavBar = ({ classes, title, onOpenMenu, history }) => {
  /**
   * Функция отправки данных на сервер
   * для разлогирования пользователей
   */
  const signOut = () => {
    auth.logout()
      .then(
        () => {
          history.push('/sign-in');
        },
      );
  };
  return(
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onOpenMenu}
          >
            <MenuIcon/>
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>

          <Button color="inherit" onClick={signOut}>
            Выход
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

NavBar.defaultProps = {
  title: '',
  onOpenMenu: undefined,
};

NavBar.propTypes = {
  classes: instanceOf(Object).isRequired,
  title: string,
  onOpenMenu: func,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(withRouter(NavBar));

import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';
import Layout, { page } from 'components/Layout/Layout';
import AccountPage from 'components/AccountPage/AccountPage';
import YearChangePage from 'components/YearChangePage/YearChangePage';
import api from 'lib/api';
import withContext from 'components/Context/withContext';
import { USER_ID } from 'lib/auth';

/**
 * Роутер для страниц основного интерфейса
 * @returns {*}
 * @constructor
 */
class MainPageRouter extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { history, context } = this.props;
    api.getContent(`report/`, {}, undefined, history)
      .then((response) => {
        const UserId = localStorage.getItem(USER_ID);
        context.setters.changeUserId(UserId)
        if (response.data.results.length > 0) {
          context.setters.changeReports(response.data.results)
          if (history.location.pathname !== '/account/settings') {
            history.push('/account/report')
          }
        } else {
          history.push('/account/settings')
        }
      })
      .catch(
        // (error) => handleError(error)
      );
  }

  render() {

    return (
      <Layout>
        <Switch>
          <Route path='/account/report' component={YearChangePage}/>
          <Route path="/account/settings" component={AccountPage}/>
        </Switch>
      </Layout>
    );
  }
}

export default withContext(withRouter(MainPageRouter));

import {
  Button,
  Grid,
  TextField,
  withStyles,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { styles } from './styles';
import Snackbar from '../../generic/Snackbar/Snackbar';
import Paper from '@material-ui/core/Paper';
import api, { DEFAULT_API } from 'lib/api';
import withContext from 'components/Context/withContext';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AddIcon from '@mui/icons-material/Add';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Add from '@material-ui/icons/Add';

/**
 * Компонент страницы Логин
 */
class AccountPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  /**
   * Конструктор класса
   * @param props передаваемые пропсы
   */
  constructor(props) {
    super(props);

    this.state = {
      user: {
        last_name: '',
        first_name: '',
        middle_name: '',
        phone: '',
        email: '',
        reports: [],
      },
      addUserAdmin: '',
      addUser: '',
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',

      openDelete: false,
      removedIndex: null,
    };
  }

  componentDidMount() {
    const { context } = this.props;
    const { reports } = context.state;
    this.loadData();
    this.setState(state => ({
      ...state,
      user: {
        ...state.user,
        reports: reports,
      }
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { context } = this.props;
    const { user } = this.state;
    const { reports } = context.state;
    if (prevProps.context.state.reports !== reports) {
      const repo = user.reports.find(item => (item.id && item.balance.saldo === ''));
      this.setState(state => ({
        ...state,
        user: {
          ...state.user,
          reports: reports.map((val, ind) => (repo && val.id === repo.id) ? {
            ...val,
            balance: { ...val.balance, saldo: '' }
          } : val)
        }
      }));
    }
  }

  loadData = () => {
    const { history } = this.props;
    api.getContent(`account/`, {}, undefined, history)
      .then((response) => {
        if (response.data) {
          const data = response.data;
          this.setState(state => ({
            ...state,
            loading: false,
            user: { ...state.user, ...data, reports: state.user.reports }
          }));
          // context.setters.changeReports(response.data.results)
        }
      })
      .catch(
        (error) => this.handleError(error)
      );
  };

  /**
   * Функция обработки неуспешного ответа с сервера
   * @param error объект ответа
   */
  handleError = (error) => {
    this.closeSnackbar();
    if (error.response) {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Ошибка сервера',
      }));
    }
  };

  addUser = (type, report) => () => {
    const { addUserAdmin, addUser } = this.state;
    const { context } = this.props;
    let data;
    if (type === 'admin') {
      if (report.users_admin.filter(x => x.email === addUserAdmin).length > 0) {
        this.closeSnackbar();
        this.setState(state => ({
          ...state,
          loading: false,
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: 'Такой пользователь админ уже есть',
        }));
        return;
      }
      data = {
        type: 'users_admin',
        user: addUserAdmin,
        reportId: report.id
      };
    } else {
      if (report.users.filter(x => x.email === addUser).length > 0 || report.users_admin.filter(x => x.email === addUser).length > 0) {
        this.closeSnackbar();
        this.setState(state => ({
          ...state,
          loading: false,
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: 'Такой пользователь уже есть',
        }));
        return;
      }
      data = {
        type: 'users',
        user: addUser,
        reportId: report.id
      };
    }
    if (data.user !== '') {
      api.sendContent(
        'report/report_add_users',
        data, 'post',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {
          this.closeSnackbar();
          if (response.data.error) {
            this.setState(state => ({
              ...state,
              loading: false,
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: response.data.error,
            }));
          } else {
            context.setters.changeReport(response.data);
          }
        })
        .catch(
          (error) => this.handleError(error)
        );
    } else {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Введите название',
      }));
    }
  };

  deleteUser = (type, id, reportId) => () => {
    const { context } = this.props;
    let data;
    if (type === 'admin') {
      data = {
        type: 'users_admin',
        userId: id,
        reportId: reportId
      };
    } else {
      data = {
        type: 'users',
        userId: id,
        reportId: reportId
      };
    }
    api.sendContent(
      'report/report_delete_users',
      data, 'delete',
      undefined,
      DEFAULT_API,
    )
      .then((response) => {
        this.closeSnackbar();
        context.setters.changeReport(response.data);
      })
      .catch(
        (error) => this.handleError(error)
      );
  };

  lostReport = (reportId) => () => {
    const { context } = this.props;
    const { userId } = context.state;
    const data = {
      userId: userId,
      reportId: reportId
    };
    api.sendContent(
      'report/report_lost',
      data, 'delete',
      undefined,
      DEFAULT_API,
    )
      .then((response) => {
        this.closeSnackbar();
        if (response.data.error) {
          this.setState(state => ({
            ...state,
            loading: false,
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: response.data.error,
          }));
        } else {
          context.setters.deleteReport(response.data.data);
        }
      })
      .catch(
        (error) => this.handleError(error)
      );
  }

  handleTextChangeUsers = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      user: {
        ...state.user,
        [name]: value,
      },
    }));
  };

  handleTextBlur = (event) => {
    const { user } = this.state;
    const { reports, ...data } = user;
    if (data) {
      api.sendContent(
        'account',
        data, 'post',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {

        })
        .catch(
          (error) => this.handleError(error)
        );
    } else {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Укажите почту и пароль',
      }));
    }
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleBalanceChange = index => (event) => {
    const { value } = event.target;
    if (value >= 0) {
      const valCor = Math.floor(value * 100) / 100;
      this.setState(state => ({
        ...state,
        user: {
          ...state.user,
          reports: state.user.reports.map((val, ind) => ind === index ? {
            ...val,
            balance: { ...val.balance, saldo: valCor ? Number(valCor) : '' }
          } : val)
        },
      }));
    }
  };

  handleNumberOnFocus = (index) => (event) => {
    const { value } = event.target;
    if (value === '0' || value === 0) {
      this.setState(state => ({
        ...state,
        user: {
          ...state.user,
          reports: state.user.reports.map((val, ind) => ind === index ? {
            ...val,
            balance: { ...val.balance, saldo: '' }
          } : val)
        },
      }));
    }
  };

  handleNumberOnBlur = (index) => (event) => {
    const { value } = event.target;
    const { user } = this.state;
    const { reports } = user;
    if (value === '') {
      this.setState(state => ({
        ...state,
        user: {
          ...state.user,
          reports: state.user.reports.map((val, ind) => ind === index ? {
            ...val,
            balance: { ...val.balance, saldo: 0 }
          } : val)
        },
      }), () => reports[index].id && this.sendReportContent(index));
    } else {
      (reports[index].id) && this.sendReportContent(index);
    }
  };

  /**
   * Функция, обрабатывающая закрыте всплывающего окна
   * @returns {Function}
   */
  closeSnackbar = () => {
    this.setState(state => ({
      ...state,
      snackbarOpen: false,
    }));
  };

  /**
   * Функция, обрабатывающая изменения в имени отчетов
   * @param event - событие измнения
   * @returns {Function}
   */
  handleReportTextChange = index => (event) => {
    const { value } = event.target;
    const { user } = this.state;
    const { reports } = user;
    this.setState(state => ({
      ...state,
      user: {
        ...state.user, reports: reports.map((val, ind) => ind === index ? { ...val, name: value } : val)
      },
    }));
  };

  handleReportTextBlur = (index) => (event) => {
    this.sendReportContent(index);
  };

  sendReportContent = (index) => {
    const { context } = this.props;
    const { user } = this.state;
    const { reports } = user;
    if (reports[index].id) {
      api.sendContent(
        'report/report_update',
        reports[index], 'post',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {
          context.setters.changeReport(response.data);
        })
        .catch(
          (error) => this.handleError(error)
        );
    } else {
      api.sendContent(
        'report/report_create',
        reports[index], 'post',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {
          context.setters.addReport(response.data);
        })
        .catch(
          (error) => this.handleError(error)
        );
    }
  };

  /**
   * Функция, обрабатывающая изменения кнопки добавить
   */
  addReportButton = () => {
    const { user } = this.state;
    const { reports } = user;
    const now = new Date();
    const year = now.getFullYear();
    const data = reports.concat({
      id: null,
      balance: { year: year, saldo: 0 },
      name: ''
    });
    this.setState((state) => ({
        ...state,
        user: { ...state.user, reports: data }
      }
    ));
  };

  handleCloseDelete = () => this.setState((state) => ({ ...state, openDelete: false }));

  preDeleteReportButton = (index) => () => this.setState((state) => ({
    ...state,
    removedIndex: index,
    openDelete: true
  }));

  /**
   * Функция, обрабатывающая изменения кнопки удалить
   * @param index
   */
  deleteReportButton = () => {
    const { context } = this.props;
    const { user, removedIndex } = this.state;
    const { reports } = user;
    const data = [...reports];
    const removed = data.splice(removedIndex, 1);
    if (removed[0].id) {
      api.sendContent(
        'report/report_delete',
        removed[0], 'delete',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {
          context.setters.deleteReport(response.data.data);
          // this.closeReport();
        })
        .catch(
          (error) => this.handleError(error)
        );
    }
    this.setState((state) => ({
      ...state,
      user: { ...state.user, reports: data },
      openDelete: false,
    }));
  };

  goToReport = (title, id) => () => {
    const { context, history } = this.props;
    this.setState({ openMenu: false });
    context.setters.changeTitle(title, id);
    history.push('/account/report');
  };

  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    const { classes, context } = this.props;
    const { userId } = context.state;
    const { user, snackbarOpen, snackbarVariant, snackbarMessage, openDelete, addUser, addUserAdmin } = this.state;
    const {
      last_name,
      first_name,
      middle_name,
      email,
      reports,
    } = user;
    const reportsList = reports.map(report => ({
      ...report,
      users: report.users.filter(x => (!report.users_admin.some(y => x.id === y.id))),
      users_admin: report.users_admin.filter(x => x.id !== Number(userId))
    }));
    return (
      <Paper className={classes.paper}>
        <Snackbar
          open={snackbarOpen}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={this.closeSnackbar}
        />
        <Dialog
          open={openDelete}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить данный отчет?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Это действие уничтожит все данные и историю данного отчета
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete} color="primary">
              Отменить
            </Button>
            <Button onClick={this.deleteReportButton} color="primary" autoFocus>
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={this.closeSnackbar}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item lg={4} xs={12}>
            <TextField
              label="Фамилия"
              fullWidth
              variant="outlined"
              name='first_name'
              value={first_name}
              onChange={this.handleTextChange}
              onBlur={this.handleTextBlur}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="outlined"
              name='last_name'
              value={last_name}
              onChange={this.handleTextChange}
              onBlur={this.handleTextBlur}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              label="Отчество"
              fullWidth
              variant="outlined"
              name='middle_name'
              value={middle_name}
              onChange={this.handleTextChange}
              onBlur={this.handleTextBlur}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.grid}>
          <TextField
            label="Почта"
            disabled
            fullWidth
            variant="outlined"
            name='email'
            value={email}
          />
        </Grid>
        <Divider/>
        <Grid item lg={12} xs={12} className={classes.gridTitle}>
          <Typography variant="h6" className={classes.mainTitle}>
            {reportsList.length === 0 ? 'Создайте свой первый отчет в меню вверху' : 'Список отчетов'}
          </Typography>
        </Grid>
        {reportsList.map((val, index) => (
          <Card
            className={classes.root}
            style={{ marginBottom: '20px' }}
            key={index}
          >
            <CardContent>
              {/*<Typography className={classes.title} color="textSecondary" gutterBottom>*/}
              {/*  После ввода наименования можно перейти к редактированию в меню сверху*/}
              {/*</Typography>*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                // className={classes.grid}
                // key={index}
              >
                <Grid item lg={12} xs={12}>
                  <TextField
                    label='Наименование отчета'
                    fullWidth
                    variant="outlined"
                    onChange={this.handleReportTextChange(index)}
                    onBlur={this.handleReportTextBlur(index)}
                    name='name'
                    value={val.name}
                    disabled={(reports[index].users_admin.filter(x => x.id === Number(userId)).length === 0)}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    label={`Начальное сальдо на ${val.balance.year} год`}
                    // label={`Начальное сальдо для отчета "${val.name}" за ${val.balance.year} год`}
                    fullWidth
                    variant="outlined"
                    type="number"
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={this.handleBalanceChange(index)}
                    onFocus={this.handleNumberOnFocus(index)}
                    onBlur={this.handleNumberOnBlur(index)}
                    name={`balance_${index}`}
                    value={val.balance.saldo}
                    disabled={(reports[index].users_admin.filter(x => x.id === Number(userId)).length === 0)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {(reports[index].users_admin.filter(x => x.id === Number(userId)).length !== 0) &&
            <CardContent>
              <Accordion>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Доступ на просмотр</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <Grid container direction="row" style={{ position: 'relative' }}>
                    <TextField
                      label='Введите email пользователя'
                      fullWidth
                      variant="outlined"
                      inputProps={{ className: classes.inputUser }}
                      onChange={this.handleTextChangeUsers}
                      name='addUser'
                      value={addUser}
                    />
                    <Fab color="primary" aria-label="add" size='small' className={classes.addFab}
                         onClick={this.addUser('user', val)}>
                      <Add/>
                    </Fab>
                  </Grid>
                  {val.users.map((user =>
                      <Chip
                        key={user.id}
                        style={{ margin: '10px 5px 0 0' }}
                        label={user.email}
                        onDelete={this.deleteUser('user', user.id, val.id)}
                      />
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Доступ на просмотр и редактирование</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <Grid container direction="row" style={{ position: 'relative' }}>
                    <TextField
                      label='Введите email пользователя'
                      fullWidth
                      variant="outlined"
                      inputProps={{ className: classes.inputUser }}
                      onChange={this.handleTextChangeUsers}
                      name='addUserAdmin'
                      value={addUserAdmin}
                    />
                    <Fab color="primary" aria-label="add" size='small' className={classes.addFab}
                         onClick={this.addUser('admin', val)}>
                      <Add/>
                    </Fab>
                  </Grid>
                  {val.users_admin.map((user =>
                      <Chip
                        key={user.id}
                        style={{ margin: '10px 5px 0 0' }}
                        label={user.email}
                        onDelete={this.deleteUser('admin', user.id, val.id)}
                      />
                  ))}
                </AccordionDetails>
              </Accordion>
            </CardContent>
            }
            <CardActions>
              <Button size="small" color="primary" onClick={this.goToReport(val.name, val.id)}>Перейти в отчет</Button>
              <Button size="small" color="primary" onClick={this.preDeleteReportButton(index)}
                      disabled={(reports[index].users_admin.filter(x => x.id === Number(userId)).length === 0)}>Удалить
                отчет</Button>
              <Button size="small" color="primary" onClick={this.lostReport(val.id)}>Выйти из отчета</Button>
            </CardActions>
          </Card>
        ))}
        <Grid
          xs={12}
          item
          className={classes.grid}
          container
          direction="row"
          justifyContent={reportsList.length === 0 ? 'center' : 'flex-end'}
          alignItems="center"
        >
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  onClick={this.addReportButton}*/}
          {/*  className={classes.button}*/}
          {/*  xs={2}*/}
          {/*  color="primary"*/}
          {/*>*/}
          {/*  Создать отчет*/}
          {/*</Button>*/}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(withContext(withRouter(AccountPage)));

import { Grid, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { styles } from './styles';
import './styles.css';

const useStyles = makeStyles(styles);

/**
 * Компонент отображения информации о вложении с возможностью просмотра файла
 * @param attachment объект файла
 * @param disabled флаг редактирования
 * @param handleDelete функция для удалени файла
 * @returns {*}
 * @constructor
 */
const AttachmentInfo = ({ attachment, disabled, handleDelete }) => {
  const classes = useStyles();
  return (
    <div style={{width: '95%'}}>
    <ReactCSSTransitionGroup
      transitionName="attachment-info"
      transitionAppear
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Grid container spacing={1} alignItems="center" className={classes.container}>
        <Grid item xs={10}>
          <Typography
            variant="subtitle2"
            className={classes.attachmentInfo}
            component={Link}
            href={attachment.file.replace("/public_html", "")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.file_name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            disabled={disabled}
            color="secondary"
            size="small"
            onClick={handleDelete}
          >
            <Delete/>
          </IconButton>
        </Grid>
      </Grid>
    </ReactCSSTransitionGroup>
      </div>
  );
};

AttachmentInfo.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.string,
    file_name: PropTypes.string,
    file_mime: PropTypes.string,
    file_size: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AttachmentInfo;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';

import Router from 'components/Router/Router';

import theme from './lib/theme';

/**
 * Функция-корневой компонент
 * @returns {*}
 */
export default () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Router/>
    </BrowserRouter>
  </MuiThemeProvider>
);

import './types';

/**
 * @type {Settings}
 */
export const settings = {
  columns: [
    {
      column: 'date',
      title: 'Дата',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    },
    {
      column: 'source_approach',
      title: 'Источник дохода',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    },
    {
      column: 'approach',
      title: 'Доход',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    },
    {
      column: 'consumption',
      title: 'Расход',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    },
    {
      column: 'source_consumption',
      title: 'Источник расхода',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    },
    {
      column: 'balance',
      title: 'Остаток',
      column_data: 'default',
      type: 'string',
      filterType: 'contains',
      value: '',
    }
  ],
  sorting: [
    { sortColumn: 'target', direction: 'asc' },
  ],

  pageSizes: [1, 5, 10, 25],
};

/**
 * Параметры для фильтрации в таблице
 * @type {filteringParams}
 */
export const filteringParams = {
  contains: '__icontains',
  exact: '',
};

/**
 * Параметры для сортировки в таблице
 * @type {sortingParams}
 */
export const sortingParams = {
  asc: '',
  desc: '-',
};

/**
 * Вложенные lookup'ы для таблиц
 * @type {PageNestedLookups}
 */
export const PageNestedLookups = {
  target: 'target',
  description: 'description',
};

/**
 * Функция для сортировки массива с числами. (Для нативного метода массивов sort)
 * @param a {number} первое число сравнения
 * @param b {number} второе число сравнения
 * @returns {number} результат их сравнения (больше или меньше)
 */
export const compareNumeric = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return undefined;
};

/**
 * Дополнительные параметры для вкладки
 * @param index индекс текущей вкладки
 * @returns {{'aria-controls': string, id: string}}
 */
export const tabLabelProps = index => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});


export const QUARTER = [
  {id: 1, name: 'Квартал №1', month: ['january', 'february', 'march'], quarter: 'quarter_1'},
  {id: 2, name: 'Квартал №2', month: ['april', 'may', 'june'], quarter: 'quarter_2'},
  {id: 3, name: 'Квартал №3', month: ['july', 'august', 'september'], quarter: 'quarter_3'},
  {id: 4, name: 'Квартал №4', month: ['october', 'november', 'december'], quarter: 'quarter_4'}
]

export const MONTH = [
  {id: 1, name: 'Январь', eng: 'january', translate:'января'},
  {id: 2, name: 'Февраль', eng: 'february', translate:'февраля'},
  {id: 3, name: 'Март', eng: 'march', translate:'марта'},
  {id: 4, name: 'Апрель', eng: 'april', translate:'апреля'},
  {id: 5, name: 'Май', eng: 'may', translate:'мая'},
  {id: 6, name: 'Июнь', eng: 'june', translate:'июня'},
  {id: 7, name: 'Июль', eng: 'july', translate:'июля'},
  {id: 8, name: 'Август', eng: 'august', translate:'августа'},
  {id: 9, name: 'Сентябрь', eng: 'september', translate:'сентября'},
  {id: 10, name: 'Октябрь', eng: 'october', translate:'октября'},
  {id: 11, name: 'Ноябрь', eng: 'november', translate:'ноября'},
  {id: 12, name: 'Декабрь', eng: 'december', translate:'декабря'},
]

export const MONTH_TYPE = {
  january : 31,
  february : 28,
  march : 31,
  april : 30,
  may : 31,
  june : 30,
  july : 31,
  august : 31,
  september : 30,
  october : 31,
  november : 30,
  december : 31,
}

export const CAP = {
  repeat_lines_january: [],
  source_approach: "",
  approach: 0,
  consumption: 0,
  source_consumption: "",
  balance: 0,
}

import { createStyles } from '@material-ui/core';

/**
 * Стили
 * @param theme стили
 * @returns {StyleRules<{}, string>}
 */
const styles = (theme) => createStyles({
  boxPhone: {
    padding: '24px 5px',
  },
});

export default styles;

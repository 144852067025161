import { createStyles } from '@material-ui/styles';

/**
 * Стили Material UI
 * @param theme {Theme} тема material ui
 * @returns {*} объект CSS-стилей
 */
export const styles = theme => createStyles({
  attachmentInfo: {
    // marginLeft: theme.spacing(2),
  },
  container: {
    margin: 0,
    padding: 0,
  },
});

import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import '../../types';
import clsx from 'clsx';
import AttachFile from '@material-ui/icons/AttachFile';
//
// const useStyles = makeStyles({
//   hover: {
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//   },
//   label: {
//     textTransform: 'capitalize',
//   },
// });

/**
 * Компонент-класс TableRowData для вывода строк таблицы
 * @param columns {Settings.columns} текст второй кнопки
 * @param classes {object} классы от родительсокго компонента
 * @param row {object} строка таблицы
 * @param DeleteRow {string} текст второй кнопки
 * @param SaveRow {string} текст второй кнопки
 * @returns {*}
 * @constructor
 */
export default function TableRowData({ classes, columns, row, openModalEditRow, nonActive, disable }) {
  const [changeRow, setChangeRow] = useState(false);
  /**
   * Функция отображения каждой ячейки строки
   * @param column_name {object} объект колонок
   */
  const RowData = (column_name) => {
    let data;
    switch (column_name.type) {
      case 'string':
        data = row[column_name.column];
        break;
      // case 'date':
      //   data = <DateFormatter value={row[column_name.column] || ''}/>;
      //   break;
      case 'bool':
        data = row[column_name.column] ? 'Да' : 'Нет';
        break;
      default:
        data = row[column_name.column];
        break;
    }
    return data;
  };

  /**
   * Функция открытия строки для редактирования
   */
  const onEditRow = () => {
    const data = columns.map((column) => ({ ...column, value: row[column.column], id: row.id }));
    setChangeRow(true)
    openModalEditRow(data);
    // if (disable) {
    //   setChangeRow(true)
    //   openModalEditRow(data);
    // }
  };

  useEffect(() => !nonActive && setChangeRow(false), [nonActive]);

  return (
    <TableRow
      hover
      onClick={onEditRow}
      className={changeRow ? clsx(classes.row, classes.rowActive) : classes.row}
      // classes={{hover: classes2.hover}}
    >
      {columns.map((column) => (
        (column.column==="date" && (row.approach_file || row.consumption_file)) ?
          <TableCell align="left" key={column.column} className={classes.cell}>
            {RowData(column)}<AttachFile style={{width: '10px', height: '10px'}}/>
          </TableCell>
          :
        <TableCell align="left" key={column.column} className={classes.cell}>
          {RowData(column)}
        </TableCell>
      ))}
    </TableRow>
  );
}

TableRowData.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  openModalEditRow: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

TableRowData.defaultProps = {
  openModalEditRow: undefined,
};

/* eslint-disable no-useless-escape, max-len */
// import isInt from 'validator/lib/isInt';
// import isEmail from 'validator/lib/isEmail';

// const clearSpaces = (value = '') => value.toString().replace(/\s/g, '');

export default {
  PasswordFieldValidator: value => {
    // Не менее 5 символов. Буквы какие-либо и цифры
    const pass = /^(?=^.{5,}$)(?=.*\d)(?=.*[a-zA-ZА-ЯёЁа-я])(?!.*\s).*$/;
    return pass.test(value);
  },
  PasswordSameFieldValidator: (value1, value2) => (value1 === value2),
  // RequiredFieldValidator: value => value && value.length !== 0,
  // IntegerValidator: value => Number.isInteger(value),
  // MinValueValidator: (value, param) => value >= param,
  // MaxValueValidator: (value, param) => value <= param,
  // MinLengthValidator: (value, param) => value.length >= param,
  // MaxLengthValidator: (value, param) => value.length <= param,
  // MoneyValidator: value => /^\d+$/.test(clearSpaces(value)),
  // MoneyMinValueValidator: (value, param) => parseInt(clearSpaces(value), 10) >= param,
  // MoneyMaxValueValidator: (value, param) => parseInt(clearSpaces(value), 10) <= param,
  // EqualLengthValidator: (value, param) => value && value.length === parseInt(param, 10),
  EmailValidator(value) {
    const en = /^[a-zA-Z0-9\.\-\_]{2,}@[a-zA-Z0-9\.\-]{2,}\.\w{2,}$/;
    const ru = /^[a-zA-Z0-9\.\-\_]{2,}@[АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя0-9\.\-]{2,}\.рф$/;
    const stop = /[\@\.\-\_]{2,}/;
    return !stop.test(value) && (en.test(value) || ru.test(value));
  },
  // EmailTrafficValidator(value, element, params) {
  //   let emailRequired = false;
  //   if (params && params[0]) {
  //     [emailRequired] = params;
  //   }
  //
  //   if (emailRequired && !value) {
  //     return false;
  //   }
  //
  //   const en = /^[a-zA-Z0-9\.\-\_]{2,}@[a-zA-Z0-9\.\-]{2,}\.\w{2,}$/;
  //   const ru = /^[a-zA-Z0-9\.\-\_]{2,}@[АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя0-9\.\-]{2,}\.рф$/;
  //   const stop = /[\@\.\-\_]{2,}/;
  //   return !stop.test(value) && (en.test(value) || ru.test(value));
  // },
  // RuTextValidator: value => /^[А-ЯЁ.\-\s№0-9]+$/i.test(value),
  // MobilePhoneValidator: value => /\+7 \(9[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/.test(value),
  // PhoneValidator: value => /8-[1-9][0-9]{9}/.test(value),
  // // DateFormatValidator: value => dateParser.isValid(value),
  // HomePhoneValidator: value => /(\+7|8) \([348][0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/.test(value),
  // DepartmentCodeValidator: value => /[0-9]{3}-[0-9]{3}/.test(value),
  // PassportSeriesValidator: value => /^[0-9]{4}$/.test(value),
  // PassportNumberValidator: value => /^[0-9]{6}$/.test(value),
  // PassportValidator: value => {
  //   const exp = /^[0-9]{4} [0-9]{6}$/;
  //   const notexp = /^0{2,}/;
  //   return exp.test(value) && !notexp.test(value);
  // },
  // AddressIndexValidator: value => /^[0-9]{6}$/.test(value),
  // MaxYearValidator: (value, param) => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && date.get('year') <= param;
  // },
  // MinYearValidator: (value, param) => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && date.get('year') >= param;
  // },
  // FutureDateValidator: value => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && date >= moment().startOf('day');
  // },
  // OldDateValidator: value => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && date <= moment();
  // },
  // MinAgeValidator: (value, param) => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && moment().diff(date, 'years') >= param;
  // },
  // TooOldDateValidator: value => {
  //   const date = dateParser.parse(value);
  //   return date.isValid() && moment().diff(date, 'years') <= 100;
  // },
  // ConfirmationCodeValidator: value => /^[0-9]{4}$/.test(value),
  // SnilsValidator: value => {
  //   const clearValue = value.replace(/\D/g, '');
  //   if (clearValue.length !== 11) return false;
  //
  //   const number = clearValue.substring(0, 9);
  //   const hash = parseInt(clearValue.substring(9), 10);
  //
  //   if (number <= '001001998') return true;
  //
  //   let calcHash = 0;
  //   for (let i = 9; i >= 1; i -= 1) {
  //     calcHash += parseInt(number.substring(9 - i, 9 - i + 1), 10) * i;
  //   }
  //
  //   if (calcHash > 101) {
  //     calcHash %= 101;
  //   }
  //
  //   if (calcHash === 100 || calcHash === 101) {
  //     return hash === 0;
  //   }
  //
  //   return hash === calcHash;
  // },
  // OneAlphabetTextValidator: value => {
  //   const ruExp = /[А-ЯЁ]/i;
  //   const enExp = /[A-Z]/i;
  //   return !(value.search(ruExp) >= 0 && value.search(enExp) >= 0);
  // },
  // PassportIssueDateValidator: value => {
  //   const birthdate = sessionStorage.getItem('birthdate');
  //   const reg = /\_/;
  //   if (!reg.test(birthdate) && !reg.test(value)) {
  //     const ageIssuedPassport = moment(value, 'DD.MM.YYYY').diff(moment(birthdate, 'DD.MM.YYYY'), 'years');
  //     const age = moment().diff(moment(birthdate), 'years');
  //     if (age >= 20 && ageIssuedPassport < 20) return false;
  //     if (age >= 45 && ageIssuedPassport < 45) return false;
  //   }
  //   return true;
  // },
};

import React, { useState } from 'react';
import Snackbar from '../../generic/Snackbar/Snackbar';

/**
 * Декоратор, подмешивающий компоненту диалоговое окно и снэкбар с функциями для их вызова
 * @param Component оборачиваемый компонент
 */
const withNotification = (Component) => (props) => {
  // Переменные состояния для снэкбара
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: 'info',
    message: '',
  });

  // Переменные состояния для снэкбара
  const [dialog, setDialog] = useState({
    open: false,
    status: 'loading',
    message: '',
    warningAcceptCallback: undefined,
  });

  /**
   * Функция, закрывающая снэкбар
   */
  const closeSnackbar = () => setSnackbar({ ...snackbar, open: false });

  /**
   * Функция вызова диалогового окна
   * @param status статус вызываемого окна
   * @param message сообщение
   * @param warningAcceptCallback функция-колбэк, вызываемая в случае принятия
   * пользователем предупреждения
   */
  const openDialog = (status = 'loading', message = '', warningAcceptCallback = undefined) => (
    setDialog({ status, message, warningAcceptCallback, open: true })
  );

  /**
   * Функция вызова снэкбара
   * @param variant тип вызываемого снэкбара
   * @param message сообщение
   */
  const openSnackbar = (variant = 'info', message = '') => (
    setSnackbar({ variant, message, open: true })
  );

  return (
    <>
      <Snackbar {...snackbar} onClose={closeSnackbar}/>
      <Component {...props} openDialog={openDialog} openSnackbar={openSnackbar}/>
    </>
  );
};

export default withNotification;

import { createStyles } from '@material-ui/styles';

/**
 * Стили Material UI
 * @param theme {Theme} тема material ui
 * @returns {*} объект CSS-стилей
 */
export const styles = theme => createStyles({
  fileUploader: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'justify',
  },
});

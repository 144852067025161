import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import CalculationPage from 'components/CalculationPage/CalculationPage';
import withContext from 'components/Context/withContext';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class YearChangePage extends React.Component {
  state = {
    index: 0,
  };

  nextYear = () => {
    const { context } = this.props;
    const { year } = context.state;
    context.setters.changeYearMonthQuarter(year+1, 'quarter_1', 'january')
    this.setState(state => ({
      index: state.index + 1
    }));
  };

  lastYear = () => {
    const { context } = this.props;
    const { year } = context.state;
    context.setters.changeYearMonthQuarter(year-1, 'quarter_4', 'january')
    this.setState(state => ({
      index: state.index - 1
    }));
  };

  render() {
    const { year, index } = this.state;
    return (
      <div>
        <VirtualizeSwipeableViews
          index={index}
          overscanSlideAfter={1}
          overscanSlideBefore={1}
          disabled
          slideRenderer={(params) =>
            <CalculationPage
              key={params.key}
              index={params.index}
              actualIndex={index}
              nextYear={this.nextYear}
              lastYear={this.lastYear}
              year={year}
            />
          }
        />
      </div>
    );
  }
}

export default withContext(YearChangePage);

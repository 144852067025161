import { Box, Typography } from '@material-ui/core';
import { node, number } from 'prop-types';
import React from 'react';

/**
 * Компонент-обёртка для других компонентов из Tabs
 * @param children {React.ReactNode} обораиваемый компонент
 * @param value {number} номер Tab
 * @param index {number} индекс компонента в массиве
 * @param props {TypographyProps} остальные пропсы
 * @returns {JSX.Element}
 * @constructor
 */
const TabContainer = ({ children, value, index, classPhone, ...props }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...props}
  >
    {value === index && <Box p={3} className={classPhone}>{children}</Box>}
  </Typography>
);

TabContainer.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

export default TabContainer;

import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { styles } from './styles';
import Snackbar from '../../generic/Snackbar/Snackbar';
import api, { AUTH_API } from 'lib/api';
import queryParams from 'lib/queryParams';
import * as qs from 'qs';
import auth from '../../lib/auth';
import validators from 'lib/validators';

/**
 * Компонент страницы подтверждения пароля
 */
class LoginPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  /**
   * Конструктор класса
   * @param props передаваемые пропсы
   */
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
        passwordRepeat: '',
      },
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',

      error: false,
      errorText: ''
    };
  }

  /**
   * Метод срабатывает после вмонтирования компонента в DOM
   */
  componentDidMount() {
    const queryObj = qs.parse(queryParams);
    const code = queryObj['code'];
    api.sendContent(
      'registration/reg_confirm',
      { code: code }, 'post',
      undefined,
      AUTH_API
    )
      .then((response) => {
          this.setState(state => ({
            ...state,
            user: {
              ...state.user,
              username: response.data.email,
            }
          }));
        },
      )
      .catch(() => this.props.history.push('/'));
  }

  /**
   * Функция отправки данных на сервер
   * для регистрации пользователей
   */
  sendData = () => {
    const { user } = this.state;
    const queryObj = qs.parse(queryParams);
    const code = queryObj['code'];
    const validSame = validators.PasswordSameFieldValidator(user.password, user.passwordRepeat);
    const valid = validators.PasswordFieldValidator(user.password || '');
    this.closeSnackbar()
    if (validSame && valid) {
      const data = { ...user, code };
      api.sendContent(
        'registration/reg_complite',
        data, 'post',
        undefined,
        AUTH_API
      )
        .then(({ data }) => {
          auth.saveToken(data.access_token, data.user.pk);
          this.props.history.push('/');
        })
        .catch(() => this.handleError);
    } else {
      let text = 'Ошибка ввода'
      text = !valid ? 'Пароль должен содержать цифры, буквы и быть не менее 5 символов' : text
      text = !validSame ? 'Пароли не совпадают' : text
      this.setState(state => ({
        ...state,
        error: true,
        errorText: text
      }));
    }
  };

  /**
   * Функция обработки неуспешного ответа с сервера
   * @param error объект ответа
   */
  handleError = (error) => {
    if (error.response) {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Произошла ошибка',
      }));
    }
  };

  /**
   * При нажатии Enter вызывается метод отправки на сервер
   * @param event
   */
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendData();
    }
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      user: {
        ...state.user,
        [name]: value,
      },
      error: false,
    }));
  };

  /**
   * Функция, обрабатывающая закрыте всплывающего окна
   * @returns {Function}
   */
  closeSnackbar = () => {
    this.setState(state => ({
      ...state,
      snackbarOpen: false,
    }));
  };

  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    const { classes } = this.props;
    const { snackbarOpen, snackbarVariant, snackbarMessage, user, errorText, error } = this.state;
    return (
      <Grid container component="main" className={classes.container}>
        <Snackbar
          open={snackbarOpen}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={this.closeSnackbar}
        />
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h4" className={classes.headerText}>
              Регистарция
            </Typography>
            <form>
              <TextField
                className={classes.fields}
                variant="outlined"
                margin="normal"
                disabled
                fullWidth
                label="Логин"
                name="login"
                autoComplete="login"
                value={user.username}
              />
              <TextField
                className={classes.fields}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Введите пароль"
                type="password"
                name="password"
                autoComplete="password"
                onChange={this.handleTextChange}
                onKeyPress={this.handleKeyPress}
                error={!!error}
                // helperText={error && errorText}
              />
              <TextField
                className={classes.fields}
                variant="outlined"
                margin="normal"
                required
                type="password"
                fullWidth
                label="Повторите пароль"
                name="passwordRepeat"
                autoComplete="password"
                onChange={this.handleTextChange}
                onKeyPress={this.handleKeyPress}
                error={!!error}
                helperText={error && errorText}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.sendData}
                className={classes.submitMain}
              >
                Подтвердить
              </Button>
            </form>
          </div>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(LoginPage);

import * as PropTypes from 'prop-types';
import React from 'react';
import { ContentContext } from 'components/Context/withContext';
import { withRouter } from 'react-router-dom';
import { MONTH } from 'lib/constants';


/**
 * Класс провайдер для контекста.
 */
class Context extends React.Component {
  /**
   * Задание глобального стейта.
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      report: {
        title: '',
        id: null
      },
      reports: [],
      year: undefined,
      month: '',
      quarter: '',
      // year: 0,
      // year: 2028,
    };
  }

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeTitle = (title, id) => this.setState(state => ({ ...state, report: { title: title, id: id}}));

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeUserId = userId => this.setState(state => ({ ...state, userId: userId }));

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeYear = year => this.setState(state => ({ ...state, year: year }))

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeMonth = month => this.setState(state => ({ ...state, month: month }))

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeQuarter = quarter => this.setState(state => ({ ...state, quarter: quarter }))

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeYearMonthQuarter = (year, quarter, month) => this.setState(state => ({ ...state, year: year, quarter: quarter, month: month }))

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeReports = reports => this.setState(state => ({ ...state, report: { title: reports[0].name, id: reports[0].id}, reports: reports }))

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  changeReport = report => {
    const { reports } = this.state;
    const data = reports.map((val, ind) => val.id === report.id ? report : val)
    this.setState(state => ({ ...state, reports: data }));
  }

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param termsModalOpen {boolean} значение открыта/закрыта
   */
  addReport = report => {
    const { reports } = this.state;
    const data = [...reports];
    data.push(report);
    this.setState(state => ({ ...state, reports: data }));
  }

  /**
   * Функция для закрытия управления модалкой с подсказками
   * @param id {number} id report
   */
  deleteReport = id => {
    const { reports } = this.state;
    const data = [...reports];
    for(let i = 0; i<reports.length; i++) {
      if (reports[i].id === id) {
        data.splice(i, 1);
      }
    }
    this.setState(state => ({ ...state, reports: data }));
  }

  /**
   * Стандартная функция рендер, которая возвращает созданный контекст.
   * @return {*}
   */
  render() {
    const { children } = this.props;
    const store = {
      state: this.state,
      setters: {
        changeTitle: this.changeTitle,
        changeReports: this.changeReports,
        changeReport: this.changeReport,
        addReport: this.addReport,
        deleteReport: this.deleteReport,
        changeYear: this.changeYear,
        changeMonth: this.changeMonth,
        changeQuarter: this.changeQuarter,
        changeYearMonthQuarter: this.changeYearMonthQuarter,
        changeUserId: this.changeUserId,
      //   setActiveStep: this.setActiveStep,
      //   setPDFBase64: this.setPDFBase64,
      //   setOrder: this.setOrder,
      //   setOrganizationArray: this.setOrganizationArray,
      //   setForm: this.setForm,
      //   setSignedFile: this.setSignedFile,
      //   startOrderGuide: this.startOrderGuide,
      //   disableOrderGuide: this.disableOrderGuide,
      //   setPdfaBase64: this.setPdfaBase64,
      //   updateOrder: this.updateOrder,
      //   setUserData: this.setUserData,
      //   openSnackbar: this.openSnackbar,
      },
      // getters: {
      //   this.state.year,
      // },
    };

    return (
      <ContentContext.Provider
        value={store}
      >
        {children}
      </ContentContext.Provider>
    );
  }
}

Context.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(Context);

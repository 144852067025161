import { createStyles } from '@material-ui/core';

/**
 * Стили
 * @param theme стили
 * @returns {StyleRules<{}, string>}
 */
const styles = (theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

export default styles;

import React from 'react';
import * as PropTypes from 'prop-types';

import { Grow, Snackbar as SnackbarBase, SnackbarContent, Typography } from '@material-ui/core';
import { CheckCircle, Error, Info, Warning, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import clsx from 'clsx';

import { styles } from './styles';

const useStyles = makeStyles(styles);

/**
 * Объект с иконками для снэкбара
 */
export const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

/**
 * Компонент, отвечающий за анимацию появления и исчезновения снэкбара
 * @param props передаваемые пропсы
 * @constructor
 */
const TransitionComponent = (props) => <Grow {...props} />;

/**
 * Компонент-обертка для содержимого снэкбара
 * @param message сообщение снэкбара
 * @param onClose функция, закрывающая снэкбар
 * @param variant тип отображаемого снэкбара
 * @param props остальные пропсы
 * @constructor
 */
const SnackbarContentWrapper = ({ message, onClose, variant, ...props }) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={clsx(classes[variant], classes.snackbar)}
      aria-describedby="snackbar"
      message={
        <Typography component="span" id="snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
          <Close onClick={onClose} className={classes.button}/>
        </Typography>
      }
      {...props}
    />
  );
};

SnackbarContentWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    'success',
    'warning',
    'error',
    'info',
  ]).isRequired,
};

/**
 * Функция определяет время через которое будет автоматически закрыт снекбар.
 * @param {String} variant - вариант снекбара
 * @return {number} - время через которое он будет закрыт
 */
const autoHideTime = (variant) => {
  if (variant === 'error') return 60000;
  return 3000;
};

/**
 * Функция определяет есть ли возможность закрыть снек бар нажав по экрану.
 * @param {Function} onClose - функция колбек которая обрабатывает закрытие
 * @param variant {String} - тип снекбара
 * @return {undefined|Function} - либо позволяет закрывать, либо нет
 */
const canCloseOutside = (onClose, variant) => {
  if (variant === 'error') return undefined;
  return onClose;
};

/**
 * Основной компонент снэкбара
 * @param message сообщение снэкбара
 * @param variant тип отображаемого снэкбара
 * @param onClose функция, закрывающая снэкбар
 * @param open переменная, отвечающая за открытие/закрытие снэкбара
 * @constructor
 */
const Snackbar = ({ message, variant, onClose, open }) => (
  <SnackbarBase
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    TransitionComponent={TransitionComponent}
    open={open}
    autoHideDuration={autoHideTime(variant)}
    onClose={canCloseOutside(onClose, variant)}
  >
    <SnackbarContentWrapper
      onClose={onClose}
      variant={variant}
      message={message}
    />
  </SnackbarBase>
);

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    'success',
    'warning',
    'error',
    'info',
  ]).isRequired,
  open: PropTypes.bool.isRequired,
};

export default Snackbar;

import React from 'react';
import { node, string } from 'prop-types';

import Drawer from './components/Drawer/Drawer';
import NavBar from './components/NavBar/NavBar';
import { Today } from '@material-ui/icons';
import withStyles from '@material-ui/styles/withStyles';
import styles from 'components/Layout/components/NavBar/styles';
import { withRouter } from 'react-router-dom';
import withContext from 'components/Context/withContext';


/**
 * Шаблон-обертка для страниц сайта.
 * Содержит стили и компоненты, которые должны быть на каждой странице.
 */
class Layout extends React.Component {
  static defaultProps = {
    title: undefined,
    reports: [],
  }

  /**
   * @property children - компоненты для отображения внутри обертки;
   * @property menuItems - объект с атрибутами для отображения в меню;
   * @property title - Заголовок для отображения в навигационной панеле;
   * @property onMenuItemClick - Callback клика по пункту меню.
   *                          Параметры:
   *                          key - Ключ item из menuItems;
   *                          item - Непосредственно item лежащий под ключем key;
   */
  static propTypes = {
    children: node.isRequired,
    // menuItems: objectOf(shape({ title: string.isRequired, icon: elementType })).isRequired,
    title: string,
    // onMenuItemClick: func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
      reports:[],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { context } = this.props;
    if (prevProps.context.state.reports !== context.state.reports) {
      const reports = context.state.reports.map((val, index) => ({id: val.id, title: val.name, icon: Today }))
      this.setState(state => ({...state, reports}))
    }
  }

  onChangeStateMenuHandler = () => this.setState(({ openMenu }) => ({
    openMenu: !openMenu,
  }))

  onMenuItemClickHandler = (title, id) => {
    const { context, history } = this.props;
    // const { onMenuItemClick } = this.props;
    this.setState({ openMenu: false });
    context.setters.changeTitle(title, id)
    if (title==='Настройки') {
      history.push('/account/settings');
    } else {
      history.push('/account/report');
    }
    // onMenuItemClick && onMenuItemClick(item);
  }

  render() {
    const { openMenu, reports } = this.state;
    const { children, context } = this.props;
    return (
      <>
        <NavBar
          title={context.state.report.title}
          onOpenMenu={this.onChangeStateMenuHandler}
        />

        <Drawer
          open={openMenu}
          reports={reports}
          onCloseMenu={this.onChangeStateMenuHandler}
          onMenuItemClick={this.onMenuItemClickHandler}
        />

        {children}
      </>
    );
  }
}

export default withStyles(styles)(withContext(withRouter(Layout)));

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';

import PublicRoute from 'components/Router/PublicRouter';
import LoginPage from 'components/LoginPage/LoginPage';
import NotFound404 from 'components/NotFound404';
import RegistrationPage from 'components/RegistrationPage/RegistrationPage';
import MainPageRouter from 'components/Router/MainPageRouter/MainPageRouter';
import PrivateRoute from 'components/Router/PrivateRouter';
import RegistrationComplitePage from 'components/RegistrationComplitePage/RegistrationComplitePage';
import Context from 'components/Context/Context';
import App from '../../App';

// import { preparePage } from './lib/utils';

/**
 * Компонент отвечающий за маршутиризацию на сайте.
 */
class Router extends React.Component {
  static defaultProps = {};

  static propTypes = {
    location: shape({
      pathname: string.isRequired,
      state: shape({
        page: shape({
          title: string.isRequired,
        }),
      }),
    }).isRequired,
    history: shape({ push: func.isRequired }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.needInit = true;
  }

  // getTitle = () => {
  //     const { location } = this.props;
  //     const { pathname, state } = location;
  //     const page = pages[pathname] || (state && state.page);
  //
  //     if (page) {
  //         this.needInit = false;
  //         return page.title;
  //     }
  //
  //     return undefined;
  // }

  // renderPage = (page) => (props) => {
  //     const { history, location } = props;
  //
  //     if (this.needInit) {
  //         this.needInit = false;
  //         history.push(location.pathname, { page: preparePage(page) });
  //
  //         return null;
  //     }
  //
  //     return <OverrideHistoryWrapper component={page.component} page={preparePage(page)} {...props}/>;
  // }
  //
  // onMenuItemClickHandler = (url, page) => (
  //   this.props.history.push(url, { page: preparePage(page) })
  // )

  render() {
    return (
      <Context>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/account"/>}/>
          <PublicRoute path="/sign-up/regConfirm" component={RegistrationComplitePage}/>
          <PublicRoute path="/sign-up" component={RegistrationPage}/>
          <PublicRoute path="/sign-in" component={LoginPage}/>
          <PrivateRoute path="/account" component={MainPageRouter}/>
          <Route component={NotFound404}/>
        </Switch>
      </Context>
    );
  }
}

export default withRouter(Router);

import auth, { TOKEN, TOKEN_HEADER } from 'lib/auth';
import session from 'lib/session';
import * as PropTypes from 'prop-types';
import React, { createElement } from 'react';

import { Redirect, Route } from 'react-router-dom';

/**
 * Роутер для неавторизованных пользователей
 * @param component компонент для редиректа
 * @param rest остальные пропсы
 * @returns {*}
 * @constructor
 */
const PublicRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem(TOKEN);
      if (token) session.defaults.headers.common.Authorization = `${TOKEN_HEADER} ${token}`;
      return (
        auth.checkToken()
          ? (<Redirect to={{ pathname: '/' }}/>)
          : (createElement(component, props))
      );
    }}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;

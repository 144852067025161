import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';

/**
 * Обертка для табличной ячейки material ui для придания ей стиля.
 */
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 'bold',
    padding:'10px 4px',
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '0.6rem',
  },
}))(TableCell);

export default StyledTableCell;

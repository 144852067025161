import session from './session';

// export const TOKEN = 'token';
export const TOKEN = 'token';
export const TOKEN_HEADER = 'Bearer';
export const USER_ID = 'user_id';

export default {
  TOKEN,

  /**
   * Функция логирования пользователя
   */
  login(user) {
    return session.post('api/auth/login/', {
      ...user,
    })
      .then(({ data }) => {
        this.saveToken(data.access_token, data.user.pk);
        return true;
      })
      .catch();
  },

  /**
   * Функция разлогирования пользователя
   */
  logout() {
    return session.post('api/auth/logout/')
      .then(() => {
        this.delHeader();
        this.delToken();
        return true;
      })
      .catch(() => false);
  },

  /**
   * Функция устанвоки токена в Header.
   */
  setHeader(token) {
    session.defaults.headers.common.Authorization = `${TOKEN_HEADER} ${token}`;
  },

  /**
   * Функция удаления токена в Header.
   */
  delHeader() {
    delete (session.defaults.headers.common.Authorization);
  },

  /**
   * Функция удаления токена в localStorage.
   */
  delToken() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USER_ID);
  },

  /**
   * Функция установки токена localStorage.
   */
  saveToken(token, pk) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(USER_ID, pk);
    this.setHeader(token);
  },

  /**
   * Функция получения токена
   */
  getToken() {
    return localStorage.getItem(TOKEN);
  },

  /**
   * Функция проверки токена
   */
  checkToken() {
    return Boolean(localStorage.getItem(TOKEN));
  },
};

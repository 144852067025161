import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import './types';
import StyledTableCell from 'components/BalanceTable/components/TableHeaderRow/components/StyledTableCell';
/**
 * @module CustomMaterialTable
 */

/**
 * Компонент-функция отображения заголовка таблицы с активированной сортировкой. Должен являться дочерним элементом
 * TableHead библиотеки material-ui.
 *
 * @type {Function}
 * @member TableHeaderRow
 *
 * @param {Object} props - React propTypes
 * @property {Sorting} sorting - объект с информацией о текущей сортировке
 * @property onRequestSort {OnRequestSort} - Функция колбек срабатывающая при вызове сортировки
 * @property headerCells {Cell[]} - Массив объектов отражающих информацию о ячейках шапки таблицы (колоноках)
 * @property tableAdd {bool} - Флаг определяющий шапку для таблиц с возможностью редактирования или без
 * @returns {Node} - Строку таблицы отображающую шапку
 */
const TableHeaderRow = ({ headerCells }) => (
  <TableRow>
    {headerCells.map((headerCell) => (
      <StyledTableCell
        key={headerCell.column}
      >
        {headerCell.title}
      </StyledTableCell>
    ))}
  </TableRow>
);

TableHeaderRow.propTypes = {
  headerCells: PropTypes.instanceOf(Array).isRequired,
};

TableHeaderRow.defaultProps = {};

export default TableHeaderRow;

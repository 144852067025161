import React from 'react';
import { bool, func } from 'prop-types';

import BaseDrawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styles from './styles.module.css';
import { Home } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import api, { DEFAULT_API } from 'lib/api';
import withContext from 'components/Context/withContext';
import Snackbar from '../../../../generic/Snackbar/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Боковое меню.
 */
class Drawer extends React.Component {
  static defaultProps = {
    open: false,
    onCloseMenu: undefined,
  };

  /**
   * @property menuItems - Обект, содержащий в себе пункты меню;
   * @property onCloseMenu - Callback закрытия меню;
   * @property open - Флаг показывающий открыто или закрыто меню;
   * @property onMenuItemClick - Callback нажатия на пункт меню;
   *
   */
  static propTypes = {
    open: bool,
    onCloseMenu: func,
    onMenuItemClick: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      nameReport: '',
      balance: 0,
      openAddReport: false,
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',
    };
  }

  handleAddReport = () => {
    const { context, onCloseMenu } = this.props;
    const { nameReport, balance } = this.state;
    const now = new Date();
    const year = now.getFullYear();
    if (nameReport.length > 0) {
      api.sendContent(
        'report/report_create',
        { name: nameReport, balance: { year: year, saldo: balance } }, 'post',
        undefined,
        DEFAULT_API,
      )
        .then((response) => {
          context.setters.addReport(response.data);
          this.setState(state => ({
            ...state,
            nameReport: '',
            balance: 0,
          }));
          this.closeReport();
          onCloseMenu()
        })
        .catch(
          (error) => this.handleError(error)
        );
    }
  };

  /**
   * Функция обработки неуспешного ответа с сервера
   * @param error объект ответа
   */
  handleError = (error) => {
    this.closeSnackbar();
    if (error.response) {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Ошибка сервера',
      }));
    }
  };

  /**
   * Функция, обрабатывающая закрыте всплывающего окна
   * @returns {Function}
   */
  closeSnackbar = () => {
    this.setState(state => ({
      ...state,
      snackbarOpen: false,
    }));
  };

  openReport = () => this.setState(state => ({ ...state, openAddReport: true }));

  closeReport = () => this.setState(state => ({ ...state, openAddReport: false }));

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({ ...state, [name]: value }));
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleBalanceChange = (event) => {
    const { value } = event.target;
    if (value >= 0) {
      const valCor = Math.floor(value * 100) / 100;
      this.setState(state => ({
        ...state,
        balance: valCor ? Number(valCor) : '',
      }));
    }
  };

  handleNumberOnFocus = (event) => {
    const { value } = event.target;
    if (value === '0' || value === 0) {
      this.setState(state => ({
        ...state,
        balance: '',
      }));
    }
  };

  handleNumberOnBlur = (event) => {
    const { value } = event.target;
    if (value === '') {
      this.setState(state => ({
        ...state,
        balance: 0,
      }));
    }
  };

  render() {
    const { open, onMenuItemClick, onCloseMenu, reports } = this.props;
    const { nameReport, openAddReport, snackbarOpen, snackbarVariant, snackbarMessage, balance } = this.state;
    return (
      <BaseDrawer
        className={styles.drawer}
        anchor="left"
        classes={{ paper: styles.paper }}
        open={open}
        onClose={onCloseMenu}
      >
        <Snackbar
          open={snackbarOpen}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={this.closeSnackbar}
        />
        <Dialog
          open={openAddReport}
          onClose={this.closeReport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Создать отчет</DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={12} xs={12}>
                <TextField
                  // error={errors[fieldName]}
                  // helperText={errorsText[fieldName]}
                  label="Наименование отчета"
                  onChange={this.handleTextChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  style={{ marginBottom: '25px', width: '100%' }}
                  name='nameReport'
                  value={nameReport}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Tooltip title="Общая сумма всех Ваших денежных средств на момент создания отчета" aria-label="add">
                <TextField
                  label='Начальное сальдо'
                  fullWidth
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: 'decimal' }}
                  onChange={this.handleBalanceChange}
                  onFocus={this.handleNumberOnFocus}
                  onBlur={this.handleNumberOnBlur}
                  name='balance'
                  value={balance}
                />
                  </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeReport} color="primary">
              Отменить
            </Button>
            <Button onClick={this.handleAddReport} color="primary" autoFocus>
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles['drawer-header']}>
          <h4>Навигация по сайту</h4>
          <IconButton onClick={onCloseMenu}>
            <ChevronLeftIcon className={styles['chevron-icon']}/>
          </IconButton>
        </div>

        <Divider/>

        <List>
          <ListItem
            button
            className={styles['menu-item']}
            onClick={() => onMenuItemClick('Настройки')}
          >
            <ListItemIcon><Home/></ListItemIcon>

            <ListItemText className={styles['menu-item-text']}>
              Настройки
            </ListItemText>
          </ListItem>
          {reports.map((item, key) => (
            <ListItem
              button
              key={key}
              className={styles['menu-item']}
              onClick={() => onMenuItemClick(item.title, item.id)}
            >
              {item.icon ? <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon> : null}

              <ListItemText className={styles['menu-item-text']}>
                {item.title}
              </ListItemText>
            </ListItem>
          ))}
          <ListItem
            button
            className={styles['menu-item']}
            onClick={this.openReport}
          >
            <ListItemIcon><AddCircleOutlineIcon/></ListItemIcon>

            <ListItemText className={styles['menu-item-text']}>
              Добавить отчет
            </ListItemText>
          </ListItem>
        </List>
      </BaseDrawer>
    );
  }
}

export default withContext(Drawer);

import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { styles } from './styles';
import Snackbar from '../../generic/Snackbar/Snackbar';
import api, { AUTH_API } from 'lib/api';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import validators from 'lib/validators';

/**
 * Компонент страницы регистрации
 */
class RegistrationComplitePage extends PureComponent {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  /**
   * Конструктор класса
   * @param props передаваемые пропсы
   */
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        email: '',
        password: '',
      },
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',
      openDialog: false,

      error: false,
      errorText: ''
    };
  }

  /**
   * Функция отправки данных на сервер
   * для регистрации пользователей
   */
  sendData = () => {
    const { user } = this.state;
    const valid = validators.EmailValidator(user.email || '');
    this.closeSnackbar()
    if (valid) {
      api.sendContent(
        'registration',
        {email:user.email}, 'post',
        undefined,
        AUTH_API
      )
        .then(() => {
            this.setState(state => ({
              ...state,
              openDialog: true,
            }));
              },
          )
        .catch((error) => this.handleError(error));
    } else {
      this.setState(state => ({
        ...state,
        error: true,
        errorText: 'Укажите корректный Email.',
      }));
    }
  };

  /**
   * Функция обработки неуспешного ответа с сервера
   * @param error объект ответа
   */
  handleError = (error) => {
    if (error.response) {
      this.setState(state => ({
        ...state,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Произошла ошибка',
      }));
    }
  };

  /**
   * При нажатии Enter вызывается метод отправки на сервер
   * @param event
   */
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendData();
    }
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      user: {
        ...state.user,
        [name]: value,
      },
      error: false,
    }));
  };

  /**
   * Функция, обрабатывающая закрыте всплывающего окна
   * @returns {Function}
   */
  closeSnackbar = () => {
    this.setState(state => ({
      ...state,
      snackbarOpen: false,
    }));
  };


  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    const { classes } = this.props;
    const { snackbarOpen, snackbarVariant, snackbarMessage, openDialog, user, error, errorText } = this.state;
    return (
      <Grid container component="main" className={classes.container}>
        <Dialog
          open={openDialog}
          // onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{textAlign: 'center'}}>Проверте почту</DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
            <Typography>
              На {user.email} отправлено подтверждение
              </Typography>
          </DialogContent>
        </Dialog>
          <Snackbar
            open={snackbarOpen}
            variant={snackbarVariant}
            message={snackbarMessage}
            onClose={this.closeSnackbar}
          />
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h4" className={classes.headerText}>
                Регистрация
              </Typography>
              <form>
                <TextField
                  className={classes.fields}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Введите электронную почту"
                  name="email"
                  placeholder="ivan@domain.ru"
                  autoComplete="email"
                  onChange={this.handleTextChange}
                  onKeyPress={this.handleKeyPress}
                  error={!!error}
                  helperText={error && errorText}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.sendData}
                  className={classes.submitMain}
                >
                  Зарегистрироваться
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/sign-in"
                  className={classes.submitSecondary}
                >
                  Назад
                </Button>
              </form>
            </div>
          </div>
        </Grid>
    );
  }
}

export default withStyles(styles)(RegistrationComplitePage);

import { createStyles } from '@material-ui/core';

/**
 * Стили
 * @param theme стили
 * @returns {StyleRules<{}, string>}
 */
const styles = (theme) => createStyles({
  labelVse: {
    color: '#1976D2',
  },
});

export default styles;

import axios from 'axios';

// Настройка функционала прерывания запроса
const { CancelToken } = axios;
export const source = CancelToken.source();

const xsrfCookieName = 'csrftoken';
const xsrfHeaderName = 'X-CSRFToken';
const cancelToken = source.token;
const timeout = 100000;
const headers = {
  'Content-Type': 'application/json',
};

/**
 * Функция, возвращающая базовый урл для работы с сервером
 * @returns {string} базовый урл для запросов на сервер
 */
export function getBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://budget.elecso.ru/';
  }
  // return 'http://localhost:8000';
  return 'http://192.168.1.42:8000';
  // return 'http://0.0.0.0:8000';
}

const baseURL = getBaseUrl();

export const axiosRequestConfig = {
  baseURL,
  cancelToken,
  xsrfCookieName,
  xsrfHeaderName,
  timeout,
  headers,
};

const session = axios.create(axiosRequestConfig);

export default session;

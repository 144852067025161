import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import styles from './styles';
import api, { DEFAULT_API } from '../../lib/api';
import { settings } from './commonInterfaces';
import TableRowData from './components/TableRowData/TableRowData';
import { withNotification } from '../../decorators';
import ModalEditRow from 'components/BalanceTable/components/ModalEditRow/ModalEditRow';
import TableHeaderRow from 'components/BalanceTable/components/TableHeaderRow';
import { CAP, MONTH } from 'lib/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import withContext from 'components/Context/withContext';


/**
 * Компонент-класс BalanceTable для выполнения отображения Таблицы
 */
class BalanceTable extends React.Component {
  static defaultProps = {};

  static propTypes = {
    openSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
  };

  /**
   * Конструктор компонента с переопределением пропсов и стейтом.
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...settings,
      // Размер таблицы
      dense: true,
      // Текущая страница
      page: 0,
      // длина выборки
      rowsPerPage: 5,

      rows: [],
      totalCount: 0,

      balance: {},

      loading: false,

      // Результирующие доход/расход за месяц
      sumApproach: 0,
      sumConsumption: 0,

      //Модалка редактирования строки
      openModalEditRow: false,
      rowEdit: {},
    };
  }

  componentDidMount() {
    this.loadDataBalance();
    this.loadDataRow();
  }

  componentDidUpdate(prevProps, prevState) {
    const { rows, balance } = this.props;
    if (prevProps.balance !== balance) {
      this.loadDataBalance();
    }
    if (prevProps.rows !== rows) {
      this.loadDataRow();
    }
  }

  loadDataBalance = () => {
    const { balance } = this.props;
    this.setState(state => ({ ...state, balance: balance }));
  };

  loadDataRow = () => {
    const { rows, balance, month, monthDataType } = this.props;
    const rowsData = [];
    for (let i = 1; i < monthDataType[month] + 1; i++) {
      rowsData.push({ ...CAP, date: i });
    }
    for (let i = 0; i < rows.length; i++) {
      rowsData.splice([rows[i].date - 1], 1, { ...rows[i], balance: 0 });
    }

    const bal = (balance && balance[month]) ? balance[month] : 0;
    let sumApproach = bal;
    let sumConsumption = 0;
    for (let i = 0; i < rowsData.length; i++) {
      if (i === 0) {
        rowsData[i].balance = Number((bal + rowsData[i].approach - rowsData[i].consumption).toFixed(2));
      } else {
        rowsData[i].balance = Number((rowsData[i - 1].balance + rowsData[i].approach - rowsData[i].consumption).toFixed(2));
      }
      sumApproach += rowsData[i].approach;
      sumConsumption += rowsData[i].consumption;
    }
    sumApproach = Number(sumApproach.toFixed(2));
    sumConsumption = Number(sumConsumption.toFixed(2));
    this.setState(state => ({ ...state, rows: rowsData, sumApproach, sumConsumption }));
  };

  /**
   * Функция обработки ошибки с сервера
   * @param error объект ошибки
   */
  handleError = (error) => {
    let message = 'Сервер недоступен, попробуйте позже';
    if (error.response) {
      message = `При сохранения произоша ошибка (код ${error.response.status})`;
    }
    this.props.openSnackbar('error', message);
    this.setState((state) => ({ ...state, loading: false, openModalEditRow: false, openOneEntry: false }));
  };

  /**
   * Функция отправки данных на сервер для сохранения изменений строки
   * @param data {Object} данные
   */
  onSaveOrAddRow = (data) => () => {
    const { loadData, context } = this.props;
    const { report, year } = context.state;
    this.setState((state) => ({ ...state, loading: true }));
    // Апи создания, если id строки нет
    if (!data.row.id && data.monthSwitchsApproach.defaultState && data.monthSwitchsConsumption.defaultState) {
      api.sendContent(
        `calculation/${year}/month_create_row`,
        { ...data, report_id: report.id }, 'post',
        undefined,
        DEFAULT_API,
      )
        .then(() => {
          this.setState((state) => ({
            ...state,
            openModalEditRow: false,
            loading: false
          }), () => loadData());
        })
        .catch((error) => this.handleError(error));
    }
    // Апи обновления, если id есть, но есть флаг состояния выключенных повторений
    else if (data.monthSwitchsApproach.defaultState && data.monthSwitchsConsumption.defaultState) {
      api.sendContent(
        `calculation/${year}/month_update_row`,
        { ...data, report_id: report.id }, 'post',
        undefined,
        DEFAULT_API,
      )
        .then(() => {
          this.setState((state) => ({
            ...state,
            openModalEditRow: false,
            loading: false
          }), () => loadData());
        })
        .catch((error) => this.handleError(error));
    }
    // Апи обновления, если выбрана комбинация повторов
    else {
      api.sendContent(
        `calculation/${year}/month_update`,
        { ...data, report_id: report.id }, 'post',
        undefined,
        DEFAULT_API,
      )
        .then(() => {
          this.setState((state) => ({
            ...state,
            openModalEditRow: false,
            loading: false
          }), () => loadData());
        })
        .catch((error) => this.handleError(error));
    }
  };

  openModalEditRow = (row) => () => {
    this.setState((state) => ({
      ...state,
      openModalEditRow: true,
      rowEdit: row,
    }));
  };

  checkFileDelete = (file) => {
    for (const mounth of MONTH) {
      if (file[mounth.eng] != null) {
          return false
      }
    }
    return true
  };

  closeModalEditRow = (files) => () => {
    const { loadData, context } = this.props;
    const { report, year } = context.state;
    const fileDelete = [];
    for (const file of files) {
      if (this.checkFileDelete(file)) {
        fileDelete.push(file);
      }
    }
    if (fileDelete.length !== 0) {
      const sendData = {
        year: year,
        report_id: report.id,
        files: fileDelete.map(f => f.id)
      };
      api.sendContent(
        `attachment/file_delete`,
        sendData,
        'delete',
        undefined,
      ).then().catch((error) => this.handleError(error));
    }
    this.setState((state) => ({
      ...state,
      openModalEditRow: false
    }));
  };

  render() {
    const { classes, month, monthDataType, type } = this.props;
    const {
      rows,
      columns,
      loading,
      openModalEditRow,
      rowEdit,
      balance,
      sumApproach,
      sumConsumption,
    } = this.state;
    const nameMonth = MONTH.find(item => item.eng === month);

    const { context } = this.props;
    const { reports, userId } = context.state;
    const repoList = reports.filter((val) => val.id === balance.report);

    return (
      <Paper className={classes.paper}>
        {loading && <div style={{ position: 'absolute', top: '40%', right: '50%' }}>
          <CircularProgress/>
        </div>}
        <ModalEditRow
          open={openModalEditRow}
          onClose={this.closeModalEditRow}
          rowEdit={rowEdit}
          onClick={this.closeModalEditRow}
          onSave={this.onSaveOrAddRow}
          monthDataType={monthDataType}
          month={month}
          disable={(repoList.length > 0 && repoList[0].users_admin.filter(x => x.id === Number(userId)).length === 0)}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              {type === 'desktop' &&
              <TableRow>
                <TableCell className={classes.head} colSpan={6} align="center">{nameMonth.name}</TableCell>
              </TableRow>}
              <TableHeaderRow
                classes={classes}
                headerCells={columns}
              />
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="left" className={clsx(classes.cell, classes.saldo)}>Сальдо
                  начальное</TableCell>
                <TableCell align="left" className={classes.cell}>{balance[month]}</TableCell>
                <TableCell align="left" className={classes.cell}/>
                <TableCell align="left" className={classes.cell}/>
                <TableCell align="left" className={classes.cell}>{balance[month]}</TableCell>
              </TableRow>
              {rows.map((row, index) => (
                <TableRowData
                  row={row}
                  index={index}
                  classes={classes}
                  columns={columns}
                  openModalEditRow={this.openModalEditRow(row)}
                  key={row.date}
                  disable={(repoList.length > 0 && repoList[0].users_admin.filter(x => x.id === Number(userId)).length > 0)}
                  nonActive={openModalEditRow}
                />
              ))}
              <TableRow>
                <TableCell colSpan={2} align="left" className={classes.cell}/>
                <TableCell align="left" className={classes.cell}>{sumApproach}</TableCell>
                <TableCell align="left" className={classes.cell}>{sumConsumption}</TableCell>
                <TableCell colSpan={2} align="left" className={classes.cell}/>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="left" className={classes.cell}/>
                <TableCell align="left" className={classes.cell}>{Number((sumApproach - sumConsumption).toFixed(2))}</TableCell>
                <TableCell colSpan={2} align="left" className={classes.cell}/>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(withContext(withNotification(BalanceTable)));

import { AppBar, Tab, Tabs } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import TabContainer from 'components/TabContainer';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { MONTH, MONTH_TYPE, QUARTER, tabLabelProps } from 'lib/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WrapperForOneMonth from 'components/WrapperForOneMonth/WrapperForOneMonth';
import WrapperForAnyMonth from 'components/WrapperForAnyMonth/WrapperForAnyMonth';
import api from 'lib/api';
import { withNotification } from '../../decorators';
import { withRouter } from 'react-router-dom';
import withContext from 'components/Context/withContext';
import styles from './styles';
const useStyles = makeStyles(styles);

/**
 * Компонент-основа для отображения всех шагов 9
 * @returns {*}
 * @constructor
 */
const CalculationPage = ({ openSnackbar, history, context, nextYear, lastYear, index, actualIndex }) => {
  const classes = useStyles();
  const [tabIndexQuarter, setTabIndexQuarter] = useState(0);
  const [tabIndexMonth, setTabIndexMonth] = useState(0);

  const [monthDataType, setMonthDataType] = useState(MONTH_TYPE);

  const theme = useTheme();

  // Переменные наличия кнопок предыдущий и след.год
  const [yearButtom, setNextYear] = useState({
    lastYear: true,
    nextYear: true,
  });

  // Переменные для месяцев
  const [yearCurrent, setYear] = useState('');

  // Набор для месяцев и кварталов
  const [monthList, setListMonth] = useState({
    month: [],
    quarter: [],
  });

  const initialMonth = () => {
    if (actualIndex !== index || context.state.report.id === null) {
      return
    }

    const now = new Date();
    const year = context.state.year ? context.state.year : now.getFullYear();
    const monthDataType = (year % 4 === 0) ? { ...MONTH_TYPE, february: 29 } : MONTH_TYPE;
    setMonthDataType(monthDataType);
    api.sendContent(`calculation/edit_month_list`, {'reportId': context.state.report.id}, undefined, history)
      .then((response) => {
        let tim;
        let lastYear;
        let month_index;
        let createMonth;
        if (response.data.year === year) {
          createMonth = response.data.month;
          let startDate = MONTH.find(item => item.eng === createMonth);
          tim = startDate.id - 1;
          lastYear=0
          month_index = now.getMonth();
          setNextYear({...yearButtom, lastYear: false})
          // lastYear = 1;
          // setNextYear({ ...yearButtom, lastYear: true });
        } else {
          tim = 0;
          month_index = 0;
          lastYear = 1;
          createMonth = 'january';
          setNextYear({ ...yearButtom, lastYear: true });
        }

        const quarterIndex = Math.floor(month_index / 3);
        const quarterDeleteIndex = Math.floor(tim / 3);
        const monthDataList = MONTH.filter((val) => val.id - 1 >= tim);
        const monthQuarterList = QUARTER.filter((val) => val.id - 1 >= quarterDeleteIndex);
        let dataMonthFull = [...monthQuarterList[0].month];
        const indexDelete = dataMonthFull.indexOf(createMonth);
        const dataMonth = dataMonthFull.slice(indexDelete);
        const monthQuarterListRedact = monthQuarterList.map((val ,ind) => ind===0? {...val, month:dataMonth}: val)

        setListMonth({ ...monthList, month: monthDataList, quarter: monthQuarterListRedact });
        setYear(year)
        context.setters.changeYear(year)
        context.setters.changeMonth(monthDataList[month_index - tim].eng)
        context.setters.changeQuarter(monthQuarterListRedact[quarterIndex - quarterDeleteIndex].quarter)
        setTabIndexQuarter(quarterIndex - quarterDeleteIndex + lastYear);
        setTabIndexMonth(month_index - tim + lastYear);
      })
      .catch(
        (error) => handleError(error)
      );
  };

  /**
   * Функция обработки ошибки с сервера
   * @param error объект ошибки
   */
  const handleError = (error) => {
    let message = 'Сервер недоступен, попробуйте позже';
    if (error.response) {
      message = `Сервер недоступен, попробуйте позже (код ${error.response.status})`;
    }
    openSnackbar('error', message);
  };

  /**
   * Функция изменения индекса активной вкладки кварталов
   * @param event объект события изменения
   * @param index инжекс новой вкладки
   */
  const handleIndexQuarterChange = (event, index) => {
    if ((yearButtom.lastYear && index === monthList.quarter.length + 1) || (!yearButtom.lastYear && index === monthList.quarter.length)) {
      nextYear()
    } else if (yearButtom.lastYear) {
      if (index === 0) {
        lastYear()
      } else {
        context.setters.changeQuarter(monthList.quarter[index-1].quarter)
        setTabIndexQuarter(index);
      }
    } else {
      context.setters.changeQuarter(monthList.quarter[index].quarter)
      setTabIndexQuarter(index);
    }
  };

  const handleIndexQuarterTabSwipe = (index) => {
    const index2=yearButtom.lastYear ? index+1 : index
    if ((yearButtom.lastYear && index2 === monthList.quarter.length + 1) || (!yearButtom.lastYear && index2 === monthList.quarter.length)) {
    } else if (yearButtom.lastYear) {
      if (index === 0) {
      } else {
        context.setters.changeQuarter(monthList.quarter[index2-1].quarter)
        setTabIndexQuarter(index2);
      }
    } else {
      context.setters.changeQuarter(monthList.quarter[index2].quarter)
      setTabIndexQuarter(index2);
    }
  };

  /**
   * Функция изменения индекса активной вкладки месяцев
   * @param event объект события изменения
   * @param index инжекс новой вкладки
   */
  const handleIndexMonthChange = (event, index) => {
    if ((yearButtom.lastYear && index === monthList.month.length + 1) || (!yearButtom.lastYear && index === monthList.month.length)) {
      nextYear()
    } else if (yearButtom.lastYear) {
      if (index === 0) {
        lastYear()
      } else {
        context.setters.changeMonth(monthList.month[index - 1].eng)
        setTabIndexMonth(index);
      }
    } else {
      context.setters.changeMonth(monthList.month[index].eng)
      setTabIndexMonth(index);
    }
  };

  const handleIndexMonthTabSwipe = (index) => {
    const index2=yearButtom.lastYear ? index+1 : index
    if ((yearButtom.lastYear && index2 === monthList.month.length + 1) || (!yearButtom.lastYear && index2 === monthList.month.length)) {
    } else if (yearButtom.lastYear) {
      if (index2 === 0) {
      } else {
        context.setters.changeMonth(monthList.month[index2 - 1].eng)
        setTabIndexMonth(index2);
      }
    } else {
      context.setters.changeMonth(monthList.month[index2].eng)
      setTabIndexMonth(index2);
    }
  };

  const matches = useMediaQuery('(min-width:950px)');

  useEffect(() => initialMonth(), [context.state.year, context.state.report]);

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={matches ? tabIndexQuarter : tabIndexMonth}
          onChange={matches ? handleIndexQuarterChange : handleIndexMonthChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {yearButtom.lastYear && <Tab label={`${yearCurrent-1} год`} />}
          {matches ?
            monthList.quarter.map((list, index) => <Tab label={list.name} key={list.id} {...tabLabelProps(index)} />)
            :
            monthList.month.map((list, index) => <Tab label={list.name} key={list.id} {...tabLabelProps(index)} />)
          }
          {yearButtom.nextYear && <Tab label={`${yearCurrent+1} год`}/>}
        </Tabs>

      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl'
          ? 'x-reverse'
          : 'x'}
        index={
          matches ?
            ((yearButtom.lastYear && tabIndexQuarter>0) ? tabIndexQuarter - 1 : tabIndexQuarter)
            :
            ((yearButtom.lastYear && tabIndexMonth>0) ? tabIndexMonth -1 : tabIndexMonth)
        }
        onChangeIndex={matches ? handleIndexQuarterTabSwipe : handleIndexMonthTabSwipe}
      >
        {matches ?
          monthList.quarter.map((list, index) => (
            <TabContainer dir={theme.direction} key={list.id} index={index}
                          value={(yearButtom.lastYear && tabIndexQuarter>0) ? tabIndexQuarter - 1 : tabIndexQuarter}>
              <WrapperForAnyMonth
                dataType={list}
                currentQuarter={list.quarter}
                currentYear={yearCurrent}
                monthDataType={monthDataType}
              />
            </TabContainer>
          ))
          :
          monthList.month.map((list, index) => (
            <TabContainer dir={theme.direction} key={list.id} index={index} classPhone={classes.boxPhone}
                          value={(yearButtom.lastYear && tabIndexMonth>0) ? tabIndexMonth-1 : tabIndexMonth}>
              <WrapperForOneMonth
                dataType={list}
                currentMonth={list.eng}
                currentYear={yearCurrent}
                monthDataType={monthDataType}
              />
            </TabContainer>
          ))
        }
      </SwipeableViews>
    </>
  );
};

export default withNotification(withContext(withRouter(CalculationPage)));

import { createStyles } from '@material-ui/core';
import React from 'react';

/**
 * Стили
 * @param theme стили
 * @returns {StyleRules<{}, string>}
 */
const styles = (theme) => createStyles({
  head: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 'bold',
    padding:'10px 4px',
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '0.6rem',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  labelCheck: {
    fontSize: '12px',
  },
  paper: {
    width: '100%',
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 250,
    fontSize: '10px',
  },
  cell: {
    padding: '4px 0 4px 10px',
    textAlign: 'left',
    fontSize: '10px',
    lineHeight: '0.6rem',
    cursor: 'default',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main + ' !important',
    },
  },
  rowActive: {
    backgroundColor: theme.palette.primary.main + ' !important',
  },
  saldo: {
    backgroundColor: theme.palette.primary.light,
  }
});

export default styles;

import React, { PureComponent } from 'react';

/**
 * Компонент страницы 404
 */
class NotFound404 extends PureComponent {
  /**
   * Базовый конструктор
   * @param props передаваемые пропсы
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    return (
      <>
        Страница не найдена
      </>
    );
  }
}

export default NotFound404;

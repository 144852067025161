import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import auth from '../../lib/auth';

import { styles } from './styles';
import Snackbar from '../../generic/Snackbar/Snackbar';

/**
 * Компонент страницы Логин
 */
class LoginPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  /**
   * Конструктор класса
   * @param props передаваемые пропсы
   */
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
      },
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',
    };
  }


  /**
   * Функция отправки данных на сервер
   * для регистрации пользователей
   */
  sendData = () => {
    const { user } = this.state;
    if (user.username && user.password) {
      auth.login(user)
        .then(
          () => {
            this.props.history.push('/');
          },
        )
        .catch((e) => this.handleError(e));
    } else {
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Укажите почту и пароль',
      }));
    }
  };

  /**
   * Функция обработки неуспешного ответа с сервера
   * @param error объект ответа
   */
  handleError = (error) => {
    this.closeSnackbar()
    if (error.response) {
      let data = 'Электонная почта или пароль неверные'
      if (error.response.data && error.response.data.message === 'Необходимо сначала пройти регистрацию') {
        data = 'Необходимо сначала пройти регистрацию. Перейдите по ссылке в почте'
      }
      this.setState(state => ({
        ...state,
        loading: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: data,
      }));
    }
  };

  /**
   * При нажатии Enter вызывается метод отправки на сервер
   * @param event
   */
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendData();
    }
  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (event) => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      user: {
        ...state.user,
        [name]: value,
      },
    }));
  };

  /**
   * Функция, обрабатывающая закрыте всплывающего окна
   * @returns {Function}
   */
  closeSnackbar = () => {
    this.setState(state => ({
      ...state,
      snackbarOpen: false,
    }));
  };

  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    const { classes } = this.props;
    const { snackbarOpen, snackbarVariant, snackbarMessage } = this.state;
    return (
        <Grid container component="main" className={classes.container}>
          <Snackbar
            open={snackbarOpen}
            variant={snackbarVariant}
            message={snackbarMessage}
            onClose={this.closeSnackbar}
          />
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h4" className={classes.headerText}>
                Вход
              </Typography>
              <form>
                <TextField
                  className={classes.fields}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Введите электронную почту"
                  name="username"
                  autoComplete="email"
                  onChange={this.handleTextChange}
                  onKeyPress={this.handleKeyPress}
                />
                <TextField
                  className={classes.fields}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  label="Введите пароль"
                  name="password"
                  autoComplete="password"
                  onChange={this.handleTextChange}
                  onKeyPress={this.handleKeyPress}
                />
                {/*<FormControlLabel*/}
                {/*  control={<Checkbox value="remember" color="primary"/>}*/}
                {/*  label="Запомнить меня"*/}
                {/*/>*/}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submitMain}
                  onClick={this.sendData}
                >
                  Войти
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/sign-up"
                  className={classes.submitSecondary}
                >
                  Регистрация
                </Button>
              </form>
            </div>
          </div>
        </Grid>
    );
  }
}

export default withStyles(styles)(LoginPage);

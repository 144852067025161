import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

import session, { source } from 'lib/session';
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import React, { PureComponent } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FilePond, registerPlugin } from 'react-filepond';

import { styles } from './styles';
import './styles.css';

registerPlugin(FilePondPluginFileValidateType);

/**
 * Компонент для загрузки файлов
 */
class FileUploader extends PureComponent {
  static propTypes = {
    uploaderText: string,
    classes: instanceOf(Object).isRequired,
    multiple: bool,
    accepted: instanceOf(Array),
    xs: number,
    server: string,
    field: string,
    fileUpdateCallback: func,
    title: oneOfType([
      string,
      instanceOf(Object),
    ]),
  };

  static defaultProps = {
    accepted: [],
    server: undefined,
    // uploaderText: 'Перетяните сюда файл или <span class="filepond--label-action">нажмите</span>, чтобы выбрать',
    uploaderText: 'Прекрепить файл',
    field: undefined,
    fileUpdateCallback: undefined,
    title: undefined,
    multiple: false,
    xs: 12,
  };

  state = {
    filesWereUploaded: false,
  };

  pond = new FilePond({});

  /**
   * Функция-колбэк, привязывающая экземпляр класса к ref
   * @param ref DOM-ссылка
   */
  addRef = (ref) => {
    this.pond = ref;
  };

  /**
   * Функция, выполняющаяся при успешной загрузке файлов
   */
  onLoadSuccess = () => {
    this.setState(state => ({ ...state, filesWereUploaded: true }));
    this.resetUploader()
  }

  /**
   * Удаляет все файлы из текущего экземпляра загрузчика, чтобы закинуть в него новые
   */
  resetUploader = async () => {
    await this.pond.removeFiles();
    this.setState(state => ({ ...state, filesWereUploaded: false }));
  };

  /**
   * Функция-конфиг для формирования запроса на отправку файла на сервер. Комменты взяты с официального сайта.
   * @param fieldName имя поля объекта файла в запросе. По умолчанию filepond
   * @param file сам объект файла
   * @param metadata метаданные
   * @param load функция-колбэк, срабатывающая после загрузки файлов на сервер
   * @param error функция-колбэк, срабатывающая в случае ошибки загрузки файлов
   * @param progress функция-колбэк, срабатывающая после загрузки файлов
   * @param abort функция-колбэк, срабатывающая в случае отмены пользователем зарузки файла на сервер
   * @returns {{abort: abort}}
   */
  fileSendProcess = async (fieldName, file, metadata, load, error, progress, abort) => {
    const { server, field, fileUpdateCallback, additional_fields } = this.props;
    // Настройка обработчика процесса отправки
    session.defaults.onUploadProgress = event => progress(event.lengthComputable, event.loaded, event.total);
    session.defaults.headers['Content-Type'] = 'multipart/form-data';
    // Формируем тело запроса
    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    formData.append('field', field);
    if (additional_fields) {
      formData.append('year', additional_fields.year);
      formData.append('report_id', additional_fields.report_id);
      formData.append('date', additional_fields.date);
      formData.append('minMoth', additional_fields.minMoth);
    }
    // Отправляем запрос
    try {
      const { data } = await session.post(server, formData);
      // Возвращаем настройки сессии в исходное положение
      session.defaults.onUploadProgress = undefined;
      session.defaults.headers['Content-Type'] = 'application/json';
      load(data);
      if (fileUpdateCallback) fileUpdateCallback(data, field);
    } catch (err) {
      // В случае ошибки поднимаем обработчик ошибок
      if (err.response) {
        error(err.response.data);
      }
      // Возвращаем настройки сессии в исходное положение
      session.defaults.onUploadProgress = undefined;
      session.defaults.headers['Content-Type'] = 'application/json';
    }
    // Возвращаем метод для остановки запроса
    return {
      abort: () => {
        // Функция вызывается в случае, если нажата отмена запроса
        source.cancel();
        // Посылаем сигнал компоненту, что запрос остановлен
        abort();
      },
    };
  };

  /**
   * Базовый метод рендера
   * @returns {*}
   */
  render() {
    const { xs, classes, title, multiple, server, uploaderText, accepted, disabled } = this.props;
    const { filesWereUploaded } = this.state;
    let resetButtonText = 'Загрузить новый файл';
    if (multiple) resetButtonText = 'Обнулить загрузчик файлов';
    const acceptedTypes = ['image/*', 'application/pdf'].concat(accepted);
    return (
      <Grid item xs={xs}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <FilePond
          acceptedFileTypes={acceptedTypes}
          labelFileTypeNotAllowed="Неверный формат файла"
          fileValidateTypeLabelExpectedTypes="Ожидалось изображение или PDF"
          server={server && { process: this.fileSendProcess }}
          allowMultiple={multiple}
          onprocessfile={this.onLoadSuccess}
          className={classes.fileUploader}
          ref={this.addRef}
          allowRevert={false}
          labelIdle={uploaderText}
          labelFileProcessing="Подождите, файл загружается..."
          labelFileProcessingError="Не удалось загрузить файл"
          labelFileProcessingComplete="Загрузка завершена"
          labelTapToCancel="Нажмите, чтобы отменить"
          labelTapToRetry="Нажмите, чтобы попробовать снова"
          disabled={disabled}
        />
        {/*<ReactCSSTransitionGroup*/}
        {/*  transitionName="file-uploader"*/}
        {/*  transitionEnterTimeout={1000}*/}
        {/*  transitionLeaveTimeout={500}*/}
        {/*>*/}
        {/*  {filesWereUploaded &&*/}
        {/*  <Button size="small" onClick={this.resetUploader}>{resetButtonText}</Button>}*/}
        {/*</ReactCSSTransitionGroup>*/}
      </Grid>
    );
  }
}

export default withStyles(styles)(FileUploader);

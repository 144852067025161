import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { MONTH } from 'lib/constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import styles from './styles';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(styles);
/**
 * Компонент-класс TableIconRow для вывода иконок и кнопок для строк таблицы
 * @param onClose {function} колбэк функция закрытия
 * @param onClick {function} колбэк функция клика
 * @returns {*}
 * @constructor
 */
const ModalEditMonth = ({ open, onClose, monthListEdit, monthDataType, row, onClick, type, minMoth }) => {
  const classes = useStyles();
  const [monthSwitchs, changeMonthState] = useState([]);

  const loadMonth = () => {
    const data = [];
    for (let i = 0; i < MONTH.length; i++) {
      if (MONTH[i].id <= minMoth.id || monthDataType[MONTH[i].eng] < row.date) {
        continue;
      }
      data.push({ ...MONTH[i], switch: !!(monthListEdit && monthListEdit[MONTH[i].eng]) });
    }
    changeMonthState(data);
  };
  /**
   * Метод вызываемый при изменении состояния галочки в чекбоксе.
   * @param event {Event} - событие изменения
   */
  const handleChangeMonth = index => (event) => {
    const { checked } = event.target;
    const dat = monthSwitchs.map((val, ind) => ((ind === index) ? { ...val, switch: checked } : val));
    changeMonthState(dat);
  };

  /**
   * Метод вызываемый при нажатии выделить/снять всё
   * @param event {Event} - событие изменения
   */
  const handleChooseAll = (event) => {
    const { checked } = event.target;
    const dat = monthSwitchs.map((val, ind) => ({ ...val, switch: checked }));
    changeMonthState(dat);
  };

  useEffect(() => loadMonth(), [monthListEdit, open, monthDataType]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle>Выберите месяца</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChooseAll}
                name='chooseAll'
                // color="primary"
              />
            }
            label="Выбрать всё"
            className={classes.labelVse}
          />
          {monthSwitchs.map((mon, index) => (
            <FormControlLabel
              key={mon.id}
              control={
                <Checkbox
                  checked={mon.switch}
                  onChange={handleChangeMonth(index)}
                  // disabled={mon.id<minMoth.id && true}
                  name={mon.eng}
                  color="primary"
                />
              }
              label={mon.name}
            />
            ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отменить
        </Button>
        <Button onClick={onClick(monthSwitchs, type)} color="primary" autoFocus>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalEditMonth.propTypes = {
  type: PropTypes.string.isRequired,
  minMoth: PropTypes.objectOf(Object).isRequired,
  monthListEdit: PropTypes.objectOf(Object).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default ModalEditMonth;

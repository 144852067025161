import { createStyles } from '@material-ui/styles';

/**
 * Стили Material UI
 * @param theme {Theme} тема material ui
 * @returns {*} объект CSS-стилей
 */
export const styles = theme => createStyles({
  paper: {
    margin: '0 auto',
    padding: '20px',
    maxWidth: '600px',
    marginTop: '50px'
  },
  grid: {
    margin: '24px 0',
  },
  gridTitle: {
    margin: '10px 0',
  },
  mainTitle: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: 'rgb(172, 170, 170)',
    // textAlign: 'justify',
  },
  title: {
    fontSize: 13,
    marginBottom: '10px',
  },
  inputUser: {
    paddingRight: '60px',
  },
  addFab: {
    position: 'absolute',
    top: '7px',
    right: '10px',
  },


  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: '0 auto',
  },
  form: {
    width: '90%',

    [theme.breakpoints.down('md')]: {
      padding: '18% 0 0 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '25% 0 0 0',
    },
  },
  submitMain: {
    margin: theme.spacing(2, 0, 0),
  },
  submitSecondary: {
    margin: theme.spacing(2, 0, 0),
  },
  formContainer: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
  },
  headerText: {
    fontFamily: 'RaleighBT',
    margin: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  },
  fields: {
    marginTop: 12,
    marginBottom: 12,
  },
  helpButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 10,
  },
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

import { createStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

/**
 * Стили
 * @param theme стили
 * @returns {StyleRules<{}, string>}
 */
const styles = (theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),

  },
  labelCheck: {
    fontSize:'12px',
  },
  titleDay: {
    color: theme.palette.primary.main,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 250,
    fontSize: '10px',
  },
  tableWrapper: {
    // overflowX: 'auto',
  },
  visuallyHidden: {
    // border: 0,
    // clip: 'rect(0 0 0 0)',
    // height: 1,
    // margin: -1,
    // overflow: 'hidden',
    // padding: 0,
    // position: 'absolute',
    // top: 20,
    // width: 1,
  },
  cell: {
    // paddingLeft: theme.spacing(3),
  },
});

export default styles;

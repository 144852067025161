import React from 'react';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ModalEditMonth from 'components/BalanceTable/components/ModalEditMonth/ModalEditMonth';
import { MONTH, tabLabelProps } from 'lib/constants';
import { withStyles } from '@material-ui/core/styles';
import { withNotification } from '../../../../decorators';
import withContext from 'components/Context/withContext';
import FileUploader from 'components/FileUploader';
import api, { DEFAULT_API } from 'lib/api';
import AttachmentInfo from 'components/AttachmentInfo';
import { Typography } from '@material-ui/core';
import session from 'lib/session';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import TabContainer from 'components/TabContainer';


/**
 * Компонент-класс ModalEditRow для вывода иконок и кнопок для строк таблицы
 * @param ClickButton1 {function} колбэк функция первой кнопки
 * @param ClickButton2 {function} колбэк функция второй кнопки
 * @param icon1 {node} иконка первой кнопки
 * @param text1 {string} текст первой кнопки
 * @param icon2 {node} иконка второй кнопки
 * @param text2 {string} текст второй кнопки
 * @returns {*}
 * @constructor
 */
class ModalEditRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      minMoth: {},
      row: {},
      approachRepeat: {},
      consumptionRepeat: {},

      monthSwitchsApproach: {},
      monthSwitchsConsumption: {},

      defaultMonthSwitchs: {},

      loading: false,

      tabIndex: 0,

      openModalMonth: false,
      typeModal: 'approachRepeat',

      attachmentsApproach: [],
      attachmentsConsumption: []
    };
  }

  componentDidMount() {
    const { month } = this.props;
    const minMoth = MONTH.find(item => item.eng === month);
    const defaultMonthSwitchs = {};
    for (let i = 0; i < MONTH.length; i++) {
      if (MONTH[i].id < minMoth.id) {
        continue;
      }
      defaultMonthSwitchs[MONTH[i].eng] = false;
      defaultMonthSwitchs.mainSwitch = false;
      defaultMonthSwitchs.defaultState = true;
    }
    this.setState(state => ({ ...state, defaultMonthSwitchs, minMoth }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { rowEdit, month, open, context } = this.props;
    const { minMoth } = this.state;
    const { report, year } = context.state;

    if ((prevProps.rowEdit !== rowEdit || prevProps.open !== open) && open) {
      const row = { ...rowEdit };
      delete row[`repeat_lines_${month}`];
      let consumptionRepeat = {};
      let approachRepeat = {};
      let monthSwitchsApproach = { mainSwitch: false, defaultState: true };
      let monthSwitchsConsumption = { mainSwitch: false, defaultState: true };
      let attachmentsApproach = [];
      let attachmentsConsumption = [];
      if (rowEdit.approach_file || rowEdit.consumption_file) {
        const sendData = {
          year,
          report_id: report.id,
          date: rowEdit.date,
          minMoth: minMoth.eng
        };
        this.setState({ loading: true });
        // api.getContent(`attachment/list_files?year=${year}&report_id=${report.id}&date=${rowEdit.date}&minMoth=${minMoth.eng}`)
        api.sendContent('attachment/list_files', sendData, 'post', undefined)
          .then((response) => {
            attachmentsApproach = response.data.attachments_approach;
            attachmentsConsumption = response.data.attachments_consumption;
            this.load_data_row(row, consumptionRepeat, approachRepeat, monthSwitchsApproach, monthSwitchsConsumption, attachmentsApproach, attachmentsConsumption);
          })
          .catch(
            // (error) => this.handleError(error)
          );
      } else {
        this.load_data_row(row, consumptionRepeat, approachRepeat, monthSwitchsApproach, monthSwitchsConsumption, attachmentsApproach, attachmentsConsumption);
      }
    }
  }

  load_data_row = (row, consumptionRepeat, approachRepeat, monthSwitchsApproach, monthSwitchsConsumption, attachmentsApproach, attachmentsConsumption) => {
    const { rowEdit, month, monthDataType } = this.props;
    const { minMoth } = this.state;

    if (rowEdit[`repeat_lines_${month}`]) {
      for (let i = 0; i < rowEdit[`repeat_lines_${month}`].length; i++) {
        if (rowEdit[`repeat_lines_${month}`][i].approach) {
          approachRepeat = rowEdit[`repeat_lines_${month}`][i];
        }
        if (!rowEdit[`repeat_lines_${month}`][i].approach) {
          consumptionRepeat = rowEdit[`repeat_lines_${month}`][i];
        }
      }
    }
    for (let i = 0; i < MONTH.length; i++) {
      if (MONTH[i].id <= minMoth.id || monthDataType[MONTH[i].eng] < row.date) {
        continue;
      }

      monthSwitchsApproach[MONTH[i].eng] = !!(approachRepeat && approachRepeat[`repeat_lines_${MONTH[i].eng}`]);
      if (approachRepeat && approachRepeat[`repeat_lines_${MONTH[i].eng}`]) {
        monthSwitchsApproach.mainSwitch = true;
        monthSwitchsApproach.defaultState = false;
      }
      monthSwitchsConsumption[MONTH[i].eng] = !!(consumptionRepeat && consumptionRepeat[`repeat_lines_${MONTH[i].eng}`]);
      if (consumptionRepeat && consumptionRepeat[`repeat_lines_${MONTH[i].eng}`]) {
        monthSwitchsConsumption.mainSwitch = true;
        monthSwitchsConsumption.defaultState = false;
      }
    }
    this.setState(state => ({
      ...state,
      row,
      approachRepeat,
      consumptionRepeat,
      monthSwitchsApproach,
      monthSwitchsConsumption,
      attachmentsApproach,
      attachmentsConsumption,
      loading: false
    }));
  };

  openMonth = (type) => () => {
    this.setState({ openModalMonth: true, typeModal: type });
  };
  onCloseMonth = () => {
    this.setState({ openModalMonth: false });
  };

  onChangeMonth = (data, type) => () => {
    const { month } = this.props;
    const { defaultMonthSwitchs } = this.state;
    let dataMonth = {};
    if (type === 'approachRepeat') {
      dataMonth = { mainSwitch: false, defaultState: false };
      for (let i = 0; i < data.length; i++) {
        if (data[i].eng === month && !data[i].switch) {
          dataMonth = { ...defaultMonthSwitchs };
          break;
        }
        dataMonth[data[i].eng] = data[i].switch;
        if (data[i].switch) {
          dataMonth.mainSwitch = true;
        }
      }
      this.setState({ monthSwitchsApproach: dataMonth, openModalMonth: false });
    } else {
      dataMonth = { mainSwitch: false, defaultState: false };
      for (let i = 0; i < data.length; i++) {
        if (data[i].eng === month && !data[i].switch) {
          dataMonth = { ...defaultMonthSwitchs };
          break;
        }
        dataMonth[data[i].eng] = data[i].switch;
        dataMonth.defaultState = false;
        if (data[i].switch) {
          dataMonth.mainSwitch = true;
        }
      }
      this.setState({ monthSwitchsConsumption: dataMonth, openModalMonth: false });
    }

  };

  /**
   * Функция, обрабатывающая изменения в текстовом поле
   * @param event - событие измнения
   * @returns {Function}
   */
  handleTextChange = (type) => (event) => {
    const { defaultMonthSwitchs } = this.state;
    const { name, value } = event.target;
    if (type === 'approachRepeat') {
      this.setState(state => ({
        ...state,
        row: { ...state.row, [name]: value },
        monthSwitchsApproach: { ...defaultMonthSwitchs }
      }));
    } else {
      this.setState(state => ({
        ...state,
        row: { ...state.row, [name]: value },
        monthSwitchsConsumption: { ...defaultMonthSwitchs }
      }));
    }
  };

  /**
   * Функция обработки отправки в родительский компонент изменения поля с процентами
   * @param index индекс объекта в массиве
   * @returns {Function}
   */
  handleNumberChange = (type) => (event) => {
    const { defaultMonthSwitchs } = this.state;
    const { name, value } = event.target;
    if (value >= 0) {
      const pass = /^\d*(\.\d{0,2})?$/;
      if (pass.test(value)) {
        if (type === 'approachRepeat') {
          this.setState(state => ({
            ...state,
            row: { ...state.row, [name]: value ? Number(value) : '' },
            monthSwitchsApproach: { ...defaultMonthSwitchs }
          }));
        } else {
          this.setState(state => ({
            ...state,
            row: { ...state.row, [name]: value ? Number(value) : '' },
            monthSwitchsConsumption: { ...defaultMonthSwitchs }
          }));
        }
      }
    }
  };

  handleNumberOnFocus = (type) => (event) => {
    const { name, value } = event.target;
    if (value === '0' || value === 0) {
      if (type === 'approachRepeat') {
        this.setState(state => ({ ...state, row: { ...state.row, [name]: '' } }));
      } else {
        this.setState(state => ({ ...state, row: { ...state.row, [name]: '' } }));
      }
    }
  };

  handleNumberOnBlur = (type) => (event) => {
    const { name, value } = event.target;
    if (value === '') {
      if (type === 'approachRepeat') {
        this.setState(state => ({ ...state, row: { ...state.row, [name]: 0 } }));
      } else {
        this.setState(state => ({ ...state, row: { ...state.row, [name]: 0 } }));
      }
    }
  };

  attachmentUpdateCallback = (attachment, field) => {
    const data = this.state[field].concat(attachment);
    this.setState(state => ({ ...state, [field]: data }));
  };

  deleteAttachment = (idAt, field) => () => {
    const { rowEdit, context } = this.props;
    const { minMoth } = this.state;
    const { report, year } = context.state;
    const sendData = {
      id: idAt,
      field,
      year: year,
      report_id: report.id,
      date: rowEdit.date,
      minMoth: minMoth.eng
    };
    const sendMethod = 'delete';
    api.sendContent(
      `attachment/file`,
      sendData,
      sendMethod,
      undefined,
    ).then(() => this.setState(state => ({
      ...state,
      [field]: state[field].filter(attachment => attachment.id !== idAt),
    })));
  };

  handleIndexChange = (event, tabIndex) => {
    this.setState(state => ({
      ...state,
      tabIndex,
    }));
  };

  handleTabSwipe = (tabIndex) => {
    this.setState(state => ({
      ...state,
      tabIndex,
    }));
  };

  render() {
    const { rowEdit, onClose, open, month, classes, onClick, onSave, monthDataType, context, disable, theme } = this.props;
    const { report, year } = context.state;
    const { loading, tabIndex, openModalMonth, row, approachRepeat, consumptionRepeat, typeModal, monthSwitchsApproach, monthSwitchsConsumption, minMoth, attachmentsApproach, attachmentsConsumption } = this.state;

    const translate = MONTH.find(item => (item.eng === month)).translate;

    const additional_fields = { 'year': year, 'report_id': report.id, 'date': rowEdit.date, 'minMoth': minMoth.eng };

    return (
      <Dialog
        open={open}
        onClose={onClose([...attachmentsApproach, ...attachmentsConsumption])}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ margin: 0 }}
      >
        <ModalEditMonth
          open={openModalMonth}
          onClose={this.onCloseMonth}
          onClick={this.onChangeMonth}
          monthListEdit={typeModal === 'approachRepeat' ? monthSwitchsApproach : monthSwitchsConsumption}
          type={typeModal}
          month={month}
          minMoth={minMoth}
          row={row}
          monthDataType={monthDataType}
        />
        {loading ?
          <DialogContent>
            <CircularProgress/>
          </DialogContent> :
          <div className="day-year-data">
            <DialogTitle id="alert-dialog-title">{`${row.date} ${translate} ${year} года`}</DialogTitle>
            <DialogContent>
              <Tabs
                value={tabIndex}
                onChange={this.handleIndexChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
              >
                <Tab label='Доход' {...tabLabelProps(0)} />
                <Tab label='Расход' {...tabLabelProps(1)} />
              </Tabs>
              <SwipeableViews
                axis={theme.direction === 'rtl'
                  ? 'x-reverse'
                  : 'x'}
                index={tabIndex}
                onChangeIndex={this.handleTabSwipe}
              >
                <TabContainer
                  value={tabIndex}
                  dir={theme.direction}
                  index={0}
                >
                  <Grid style={{ padding: '0', margin: '-20px -15px -24px' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={9}>

                        <TextField
                          // error={errors[fieldName]}
                          // helperText={errorsText[fieldName]}
                          label="Источник дохода"
                          onChange={this.handleTextChange('approachRepeat')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          style={{ marginBottom: '10px', width: '100%' }}
                          name='source_approach'
                          value={row.source_approach || ''}
                          disabled={disable}
                        />
                        <TextField
                          // error={errors[fieldName]}
                          // helperText={errorsText[fieldName]}
                          label="Доход"
                          onChange={this.handleNumberChange('approachRepeat')}
                          onFocus={this.handleNumberOnFocus('approachRepeat')}
                          onBlur={this.handleNumberOnBlur('approachRepeat')}
                          type="number"
                          fullWidth
                          variant="outlined"
                          inputProps={{ inputMode: 'decimal' }}
                          margin="normal"
                          style={{ marginBottom: '10px', width: '100%' }}
                          name='approach'
                          value={row.approach}
                          disabled={disable}
                          // InputProps={{ inputComponent: MoneyMask }}
                        />
                      </Grid>
                      <Grid item xs={3}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              // checked={!!(row[`repeat_lines_${month}`] && row[`repeat_lines_${month}`].find(item => item.approachRepeat === true))}
                              // checked={monthSwitchsApproach.length > 0}
                              checked={monthSwitchsApproach.mainSwitch}
                              onChange={this.openMonth('approachRepeat')}
                              name="approachRepeat"
                              color="primary"
                              disabled={disable}
                            />}
                          label="Повторять"
                          classes={{ label: classes.labelCheck }}
                          labelPlacement="top"
                        />
                      </Grid>
                    </Grid>
                    <FileUploader
                      multiple
                      xs={12}
                      additional_fields={additional_fields}
                      field="attachmentsApproach"
                      server={`api/${DEFAULT_API}/attachment/file/`}
                      fileUpdateCallback={this.attachmentUpdateCallback}
                      disabled={disable}
                    />
                    {attachmentsApproach && attachmentsApproach.map(attachment => (
                      <AttachmentInfo
                        key={attachment.id}
                        attachment={attachment}
                        disabled={disable}
                        handleDelete={this.deleteAttachment(attachment.id, 'attachmentsApproach')}
                      />
                    ))}
                  </Grid>
                </TabContainer>
                <TabContainer
                  value={tabIndex}
                  dir={theme.direction}
                  index={1}
                >
                  <Grid style={{ padding: '0', margin: '-20px -15px -24px' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={9}>
                        <TextField
                          // error={errors[fieldName]}
                          // helperText={errorsText[fieldName]}
                          label="Источник расхода"
                          onChange={this.handleTextChange('consumptionRepeat')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          style={{ marginBottom: '10px', width: '100%' }}
                          name='source_consumption'
                          value={row.source_consumption || ''}
                          disabled={disable}
                        />
                        <TextField
                          // error={errors[fieldName]}
                          // helperText={errorsText[fieldName]}
                          label="Расход"
                          onChange={this.handleNumberChange('consumptionRepeat')}
                          onFocus={this.handleNumberOnFocus('consumptionRepeat')}
                          onBlur={this.handleNumberOnBlur('consumptionRepeat')}
                          type="number"
                          fullWidth
                          variant="outlined"
                          inputProps={{ inputMode: 'decimal' }}
                          margin="normal"
                          style={{ marginBottom: '10px', width: '100%' }}
                          name='consumption'
                          value={row.consumption}
                          disabled={disable}
                        />
                      </Grid>
                      <Grid item xs={3}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              // checked={!!(row[`repeat_lines_${month}`] && row[`repeat_lines_${month}`].find(item => item.approach === false))}
                              // checked={monthSwitchsConsumption.length > 0}
                              checked={monthSwitchsConsumption.mainSwitch}
                              onChange={this.openMonth('consumptionRepeat')}
                              name="consumptionRepeat"
                              color="primary"
                              disabled={disable}
                            />}
                          classes={{ label: classes.labelCheck }}
                          label="Повторять"
                          labelPlacement="top"
                        />
                      </Grid>
                    </Grid>
                    <FileUploader
                      multiple
                      xs={12}
                      additional_fields={additional_fields}
                      field="attachmentsConsumption"
                      server={`api/${DEFAULT_API}/attachment/file/`}
                      fileUpdateCallback={this.attachmentUpdateCallback}
                      disabled={disable}
                    />
                    {attachmentsConsumption && attachmentsConsumption.map(attachment => (
                      <AttachmentInfo
                        key={attachment.id}
                        attachment={attachment}
                        editable
                        handleDelete={this.deleteAttachment(attachment.id, 'attachmentsConsumption')}
                        disabled={disable}
                      />
                    ))}
                  </Grid>
                </TabContainer>
              </SwipeableViews>
            </DialogContent>

            <DialogActions style={{ paddingTop: 0 }}>
              <Button onClick={onClick([...attachmentsApproach, ...attachmentsConsumption])} color="primary">
                Отменить
              </Button>
              {!disable &&
              <Button onClick={onSave({
                row,
                approachRepeat,
                consumptionRepeat,
                monthSwitchsApproach,
                monthSwitchsConsumption,
                minMoth,
                attachmentsApproach,
                attachmentsConsumption
              })} color="primary" autoFocus>
                Сохранить
              </Button>}
            </DialogActions>
          </div>}
      </Dialog>
    );
  }
}

// TableEditRow.propTypes = {
//   icon1: PropTypes.node.isRequired,
//   text1: PropTypes.string.isRequired,
//   icon2: PropTypes.node.isRequired,
//   text2: PropTypes.string.isRequired,
//   ClickButton1: PropTypes.func.isRequired,
//   ClickButton2: PropTypes.func.isRequired,
// };

export default withStyles(styles, { withTheme: true })(withContext(withNotification(ModalEditRow)));

import React from 'react';
import { objectOf } from 'prop-types';

import BalanceTable from 'components/BalanceTable/BalanceTable';
import Grid from '@material-ui/core/Grid';
import api from 'lib/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import styles from 'components/BalanceTable/styles';
import { withNotification } from '../../decorators';
import withContext from 'components/Context/withContext';

/**
 * Шаблон-обертка для компонента WrapperForOneMonth.
 * Содержит стили и компоненты, которые должны быть на каждой странице.
 */
class WrapperForOneMonth extends React.Component {

  /**
   * @property children - компоненты для отображения внутри обертки;
   * @property menuItems - объект с атрибутами для отображения в меню;
   * @property title - Заголовок для отображения в навигационной панеле;
   * @property onMenuItemClick - Callback клика по пункту меню.
   *                          Параметры:
   *                          key - Ключ item из menuItems;
   *                          item - Непосредственно item лежащий под ключем key;
   */
  static propTypes = {
    dataType: objectOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      january_year: [],
      february_year: [],
      march_year: [],
      april_year: [],
      may_year: [],
      june_year: [],
      july_year: [],
      august_year: [],
      september_year: [],
      october_year: [],
      november_year: [],
      december_year: [],
      opening_balance: {},
      report: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { context, currentYear, currentMonth } = this.props;
    const { report, month, year  } = context.state;
    if ((prevProps.context.state.month !== month || prevProps.context.state.report !== report || prevProps.context.state.year !== year )
      && year === currentYear && month === currentMonth) {
      this.loadData()
    }
  }

  /**
   * Функция запроса на сервер
   */
  loadData = () => {
    const { context } = this.props;
    const { report, month, year } = context.state;
    if (month && year && report.id) {
      this.setState({loading: true})
      api.getContent(`calculation/${year}/month/?type_request=${month}&report_id=${report.id}`)
        .then((response) => (
          this.setState(state => ({
            ...state,
            ...response.data,
            loading: false,
          }))
        ))
        .catch(
          (error) => this.handleError(error)
        );
    }
  };

  /**
   * Функция обработки ошибки с сервера
   * @param error объект ошибки
   */
  handleError = (error) => {
    let message = 'Сервер недоступен, попробуйте позже';
    if (error.response) {
      message = `При загрузке произошла ошибка (код ${error.response.status})`;
    }
    this.props.openSnackbar('error', message);
    this.setState((state) => ({ ...state, loading: false }));
  };

  render() {
    const { loading } = this.state;
    const { dataType, monthDataType, year } = this.props;

    return (
      <Grid item xs={12}>
        {loading && <div style={{position: 'absolute', top: '33%', left: '40%', zIndex: 1000}}>
          <CircularProgress/>
        </div>}
        <BalanceTable
          rows={this.state[`${dataType.eng}_year`]}
          // balance={opening_balance}
          balance={this.state}
          month={dataType.eng}
          loadData={this.loadData}
          monthDataType={monthDataType}
          year={year}
          type='mobile'
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(withContext(withNotification(WrapperForOneMonth)));
